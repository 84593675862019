import React from 'react';

import _ from 'lodash';

import { FileUpload } from 'primereact/fileupload';
import {Toast} from 'primereact/toast';

import ModalImage from './ModalImage';
import Api from '../utils/Api';

export default class EditFieldPhoto extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      filenames: this.props.value,
    }

    this.Api = new Api();

    this.onUpload = this.onUpload.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onError = this.onError.bind(this);
  }

  /*
  callback when the upload completes
  */
  onError(event) {

    console.error('upload error');
    console.error(event);

    this.toast.show({
      severity: 'error',
      summary: 'Error',
      detail: 'Error uploading files, please ensure they are jpegs and try again.',
      style: {'white-space': 'pre-wrap'}
    });

  }

  onUpload(event) {

    var response = event.xhr
    if (response.status !== 200) {
      console.error('upload error');
      console.error(response);

      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: 'upload error',
        style: {'white-space': 'pre-wrap'}
      });

      return;
    }

    // upload was success, add the new photos to the field
    var response_data = JSON.parse(event.xhr.response);
    var filenames = _.cloneDeep(this.state.filenames);
    _.forEach(response_data['processed_filenames'], (value, index) => {
      filenames.push(value);
    });
    filenames = _.uniq(filenames);
    this.setState({filenames: filenames});

    // save the new photo/s to the parent form
    this.props.onChange(this.props.field.uuid, filenames);

  }

  onProgress(event) {
    // console.log('progress');
    console.log(event.progress);
  }

  render() {

    if (!this.props.field) return <div />

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />

        <div className="p-grid p-justify-start">
          {
            this.state.filenames.map((photo_uuid, i) => {
              // ivan add new photo here with progress somehow

              return <div key={photo_uuid} className="p-col-fixed" style={{ width: '100px' }}>
                <ModalImage
                  className="modal-image-small"
                  alt={photo_uuid}
                  uuids={this.state.filenames}
                  myIndex={i}
                />
              </div>;
            })
          }
        </div>

        <div >
          <FileUpload
            name="file"
            url={this.Api.photos.getUploadUrl()}
            accept="image/jpg"
            emptyTemplate={<p className="p-m-0">Drag and drop files to here to upload.</p>}
            multiple
            chooseLabel="Select"
            uploadLabel="Upload"
            cancelLabel="Clear"
            maxFileSize={10000000}
            onUpload={this.onUpload}
            onProgress={this.onProgress}
            onError={this.onError}
          />
        </div>

      </div>
    )
  }

}
