import {request} from './request';
import {getToken, getUser} from './utils';

export class Api {


  auth= {
    login: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/login/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    logout: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/logout/', {
        method: "POST"
      }).then(function(res) {
        return res
      });
    }
  }

  contact = {
    send: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/contact/', {
        method: 'POST',
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
  }

  results = {
    list: function(survey_code) {
      return request(process.env.REACT_APP_API_HOST + '/api/results/?survey_code=' + survey_code, {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    save: function(data) {
      var method = 'POST';
      var url = process.env.REACT_APP_API_HOST + '/api/results/';
      return request(url, {
        method: method,
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    delete: function(result_uuid) {
      var url = process.env.REACT_APP_API_HOST + '/api/results/' + result_uuid + '/';
      return request(url, {
        method: 'DELETE'
      }).then(function(res) {
        return res
      });
    },
  }

  surveys = {
    list: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/surveys/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    fetch: function(survey_code) {
      return request(process.env.REACT_APP_API_HOST + '/api/surveys/' + survey_code + '/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    save: function(data) {
      var method = 'POST';
      var url = process.env.REACT_APP_API_HOST + '/api/surveys/';
      if (data.code) {
        method = 'PATCH';
        url = process.env.REACT_APP_API_HOST + '/api/surveys/' + data.code + '/';
      }
      return request(url, {
        method: method,
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    delete: function(surveyCode) {
      var url = process.env.REACT_APP_API_HOST + '/api/surveys/' + surveyCode + '/';
      return request(url, {
        method: 'DELETE'
      }).then(function(res) {
        return res
      });
    },
    clone: function(surveyCode) {
      var url = process.env.REACT_APP_API_HOST + '/api/surveys/' + surveyCode + '/clone/';
      return request(url, {
        method: 'POST'
      }).then(function(res) {
        return res
      });
    },
    csv: function(code, photos, separator, timezone) {
      window.location.href = process.env.REACT_APP_API_HOST + '/api/surveys/' +
        code + `/download-csv/?include-photos=${photos}&separator=${separator}&timezone=${timezone}&token=${getToken()}`;
      ;
    },
    kmz: function(code, photos) {
      window.location.href = process.env.REACT_APP_API_HOST + '/api/surveys/' +
        code + '/download-kmz/?include-photos=' + photos + '&token=' + getToken();
    },
    getQRURL: function(code) {
      return process.env.REACT_APP_API_HOST + '/api/surveys/' + code + '/download-qrc/';
    },
  }

  photos = {
    getUrl: function(uuid, thumbnailMode) {
      if (thumbnailMode === undefined) {
        thumbnailMode = false;
      }

      return process.env.REACT_APP_API_HOST + '/api/photos/' + uuid + '/download/?thumbnail=' + thumbnailMode;
    },
    getUploadUrl: function() {
      return process.env.REACT_APP_API_HOST + '/api/photos/';
    },
  }

  users = {
    fetch: function(username) {
      return request(process.env.REACT_APP_API_HOST + '/api/users/' + username + '/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },

    changePassword: function(password) {

      var url = process.env.REACT_APP_API_HOST + '/api/users/' + getUser().username + '/change-password/';
      return request(url, {
        method: 'POST',
        body: JSON.stringify({password: password})
      }).then(function(res) {
        return res
      });

    }

  }


}

export default Api;
