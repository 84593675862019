import React from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Toast} from 'primereact/toast';

import _ from 'lodash';

import Api from '../utils/Api';
import EditFieldText from './EditFieldText';
import EditFieldDate from './EditFieldDate';
import EditFieldChoice from './EditFieldChoice';
import EditFieldMChoice from './EditFieldMChoice';
import EditFieldGPS from './EditFieldGPS';
import EditFieldPhoto from './EditFieldPhoto';
import EditFieldGrid from './EditFieldGrid';


class DialogEditResult extends React.Component {

  constructor(props) {
    super(props);

    this.Api = new Api();

    this.state = {
      visible: this.props.visible,
      result: this.props.result,
      survey: this.props.survey,
    }
    this.hide = this.hide.bind(this);
    this.save = this.save.bind(this);
    this.onChange = this.onChange.bind(this);

  }

  componentDidUpdate(prevProps) {
    if (prevProps.result !== this.props.result) {
      // the parent component has updated our props, update our state accordingly
      this.setState({result: this.props.result});
    }
  }

  hide(success) {
    this.props.onHide(success);
  }

  /*
  Save the result using the same endpoint / structure as the mobile app
  */
  save() {
    console.log('save');
    console.log(this.state.result);

    // simulate the mobile app structure
    let payload = {}
    payload.result_uuid = this.state.result.uuid;
    payload.result_data = this.state.result;
    payload.survey_uuid = this.props.survey.uuid;

    // send to endpoint
    this.Api.results.save(payload).then((res) => {
      // hide with call to refresh data in Analyze.js
      this.hide(true);
    }, () => {
      this.hide(false);
    });

  }

  /*
  a field was changed, updated its the result state in the DialogEditResult which will
  cascade it to the field itself also
  */
  onChange(fieldUuid, value) {
    let result = _.cloneDeep(this.state.result);
    result[fieldUuid] = value;
    this.setState({result: result});
  }

  getEditField(field) {

    switch (field.type) {
      case 'text':
        return <EditFieldText
          field={field}
          value={this.state.result[field.uuid]}
          onChange={this.onChange}
        />
      case 'date':
        return <EditFieldDate
          field={field}
          value={this.state.result[field.uuid]}
          onChange={this.onChange}
        />
      case 'choice':
        return <EditFieldChoice
          field={field}
          value={this.state.result[field.uuid]}
          onChange={this.onChange}
        />
      case 'mchoice':
        return <EditFieldMChoice
          field={field}
          value={this.state.result[field.uuid]}
          onChange={this.onChange}
        />
      case 'gps':
        return <EditFieldGPS
          field={field}
          value={this.state.result[field.uuid]}
          onChange={this.onChange}
        />
      case 'photo':
        return <EditFieldPhoto
          field={field}
          value={this.state.result[field.uuid]}
          onChange={this.onChange}
        />
      case 'grid':
        return <EditFieldGrid
          field={field}
          value={this.state.result[field.uuid]}
          onChange={this.onChange}
        />
      default:
        return <EditFieldText
        field={field}
        value={this.state.result[field.uuid]}
        onChange={this.onChange}
      />
    }

  }
  render() {
    // ensure required fields are filled out
    const isValid = true;

    const footer = (
      <div>
        <Button
          label="Cancel"
          className="space-right"
          icon="pi pi-times"
          onClick={() => this.hide()}
        />
        <Button
          label="Save"
          disabled={!isValid}
          icon="pi pi-save"
          onClick={() => this.save()}
        />
      </div>
    );

    if (!this.props.survey || !this.props.pages) {
      return <div/>
    }

    return (

      <Dialog
        header="Edit result"
        onHide={this.hide}
        visible={this.props.visible}
        dismissableMask={false}
        breakpoints={{'960px': '75vw'}} style={{width: '50vw'}}
        footer={footer}>

        <Toast ref={(el) => this.toast = el} />

        {this.props.pages.map((page, index) =>
          <div className='edit-result-page' key={page.uuid}>

            <div className='edit-result-page-label'>
              {page.label}
            </div>

            {_.filter(this.props.fields, { pageUuid: page.uuid }).map(
              (field, index) =>
                <div key={field.uuid}>
                  <div className='edit-result-field-label'>{field.label}</div>

                  { this.getEditField(field) }


                </div>
              )
            }
          </div>
        )}

      </Dialog>

    );
  }
}

export default DialogEditResult
