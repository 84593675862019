import React from 'react';

import {Card} from 'primereact/card';

import tick from '../static/tick.png';
import cross from '../static/cross.png';
import paypal from '../static/paypal_logo.png'

class About extends React.Component {

  render() {
    return (
      <div className="p-grid p-align-center" style={{marginTop: '30px'}}>



        {/*  ABOUT  */}
        <div className="p-col-12 p-md-6">
          <Card title="Results in realtime"
                className="home-card p-d-flex p-jc-center p-ai-center">
            TapSee provides real-time access via the web to the survey data as it is entered,
            with teams able to collect results for the same survey, submitting them to a
            central database.
            <br /><br />
            If a network is not present, TapSee stores survey responses on the device, and they
            can be uploaded in bulk when a connection is available.
          </Card>
        </div>
        <div className="p-col-12 p-md-6 center">
          <Card className="p-shadow-24">
            <iframe title="demo-movie" width="100%" style={{minHeight: "300px"}} src="https://www.youtube.com/embed/cG3gW02woI8" frameBorder="0" cc_load_policy="0" allowFullScreen modestbranding="true"></iframe>
          </Card>
        </div>


        {/*  COST */}
        <div className="p-col-12 p-md-6">
          <Card title="Licensing / Cost"
                className="home-card p-d-flex p-jc-center p-ai-center">
            Evaluating TapSee is free, simply click up the top on <a href="#/create/">Create</a> and you can
            create a new survey immediately.
            <br /><br />
            Note that in evaluation mode, all survey results are deleted from the server after
            7 days.
            In evaluation mode, the maximum number of responses to a survey is 100, and photos are
            limited to 200 megabytes.
            <br /><br />
            In paid mode, an unlimited number of surveys can be created, and their results stay
            on the server for the duration of the year purchased.
            Photo storage is limited to either 500 Mb or 10 Gb, depending on the plan purchased.
            <br /><br />
            Payment for TapSee is made via credit card or PayPal.
            This provides a highly secure and traceable method for payment, allowing you to
            subscribe with complete confidence.
            <br/><br/>
            <img src={paypal} alt='paypal' style={{'width': '150px'}}/>
          </Card>
        </div>

        {/*  FEATURES MATRIX */}
        <div className="p-col-12 p-md-6 center">
          <Card className="p-shadow-24">

            <table className="features-matrix">
              <thead>
                  <tr>
                    <th >Feature</th>
                    <th style={{"width": "20%"}}>Free Version</th>
                    <th style={{"width": "20%"}}><b>100 € / year</b></th>
                    <th style={{"width": "20%"}}><b>300 € / year</b></th>
                  </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='align-left'>Unlimited surveys</td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                </tr>

                <tr>
                  <td className='align-left'>Unlimited devices</td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                </tr>

                <tr>
                  <td className='align-left'>Export to Excel (CSV)</td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                </tr>

                <tr>
                  <td className='align-left'>Export to Google Earth (KMZ)</td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                </tr>

                <tr>
                  <td className='align-left'>Maximum responses per survey</td>
                  <td>100</td>
                  <td>Unlimited</td>
                  <td>Unlimited</td>
                </tr>

                <tr>
                  <td className='align-left'>Password protection of results<br/>(additional to survey code)</td>
                  <td><img alt="cross" src={cross}/></td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                </tr>

                <tr>
                  <td className='align-left'>Delete responses on server</td>
                  <td><img alt="cross" src={cross}/></td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                </tr>

                <tr>
                  <td className='align-left'>Duplicate survey<br/>(create survey from template)</td>
                  <td><img alt="cross" src={cross}/></td>
                  <td><img alt="tick" src={tick}/></td>
                  <td><img alt="tick" src={tick}/></td>
                </tr>

                <tr>
                  <td className='align-left'>Lifetime of data on TapSee server</td>
                  <td>1 week</td>
                  <td>Duration of contract</td>
                  <td>Duration of contract</td>
                </tr>

                <tr>
                  <td className='align-left'>Limit of photos</td>
                  <td>200 MB</td>
                  <td>500 MB</td>
                  <td><b>10 GB</b></td>
                </tr>
              </tbody>
            </table>

          </Card>
        </div>


        {/*  GET STARTED */}
        <div className="p-col-12 p-md-12">
          <Card title="Get started !" className="home-card  p-d-flex p-jc-center p-ai-center">

            So what are you waiting for ? Click up the top on <a href='/#/create/'>Create</a> and make a test survey right away.
            <br/><br/>

            Remember, to use TapSee, you'll need to
            <a href='https://play.google.com/store/apps/details?id=org.tapsee'> download the
            mobile application</a> from Google Play, it's free !
            <br/><br/>

            If you have any queries, don't hesitate to <a href='/#/contact'>contact us.</a>
          </Card>
        </div>

      </div>
    )
  }
}

export default About;
