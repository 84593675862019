import React from 'react';

import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';

import NavBar from './NavBar';
import Home from './Home';
import About from './About';
import Login from './Login';
import Analyze from './Analyze';
import Create from './Create';
import Clients from './Clients';
import Contact from './Contact';
import Privacy from './Privacy';
import {getUser} from '../utils/utils.js'

class MainPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: getUser()
    }
    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  // called by Login following a successfull login
  onLogin(userDetail) {
    if (userDetail) {
      this.setState({user: userDetail});
      localStorage.setItem('user', JSON.stringify(userDetail));
    }
    window.location.href = "/#/";
  }

  // called by NavBar following successfull logout
  onLogout(detail) {
    localStorage.removeItem('user');
    this.setState({user: null});
    window.location.href = "/#/";
  }

  render() {
    return (
      <div>
        <NavBar parentState={this.state} onLogout={this.onLogout} />
        <div className="mainpage-wrapper">
          <div>
            <div className="p-grid p-nogutter">
              <div className="p-col-0 p-sm-0 p-md-1 p-lg-1"></div>
                <div className="p-col-12 p-sm-12 p-md-10 p-lg-10">
                  <div className="p-component">
                    <HashRouter>
                      <Switch>
                        <Route exact path="/home/" component={Home} />
                        <Route exact path="/about/" component={About} />
                        <Route path="/contact/" component={Contact} />
                        <Route path="/privacy/" component={Privacy} />
                        <Route path="/analyze/:code?/" component={Analyze} />
                        <Route path="/create/:code?/" component={Create} />
                        <Route exact path="/clients/" component={Clients} />

                        <Route exact path="/login" render={() => <Login onLogin={this.onLogin} />} />
                        <Redirect from="*" to="/home" />
                      </Switch>
                    </HashRouter>
                  </div>
                </div>
              <div className="p-col-0 p-sm-0 p-md-1 p-lg-1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainPage;
