import React from 'react';

import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {InputTextarea} from 'primereact/inputtextarea'
import {InputText} from 'primereact/inputtext'
import { Captcha } from 'primereact/captcha';

import Api from '../utils/Api';

class Contact extends React.Component {


  constructor(props) {
    super(props);

    this.Api = new Api();

    this.state = {
      name: '',
      email: '',
      message: '',
      recaptcha: '',
      loading: false
    }

    this.send = this.send.bind(this);
    this.reCaptchaResponse = this.reCaptchaResponse.bind(this);

  }

  send() {

    let payload = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      recaptcha: this.state.recaptcha
    }

    this.Api.contact.send(payload).then((res) => {

      this.toast.show({
        severity: 'success',
        summary: 'Success',
        detail: <div>Message sucessfully sent.</div>
      });

    }).catch((err) => {

      console.error(err);
      let message = '';
      if (err.detail) {
        message = err.detail;
      }

      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        style: {'white-space': 'pre-wrap'}
      });

    });

  }

  reCaptchaResponse(response) {

    this.setState({
      recaptcha: response.response
    });

  }


  render() {


    return (
      <div>

        <Toast ref={(el) => this.toast = el} />

        <div className='table-title'>
          <label>Questions ? Let's talk.</label>
        </div>


        <div style={{margin: '10px 0px 20px 0px'}}>

          <span className='discreet'>
              Please note that we take the privacy of email addresses very seriously and will never
              share your contact details with anyone.
          </span>
        </div>


        <div className="p-fluid">
          <div className="p-field">
              <label htmlFor="name">Name</label>
              <InputText
                value={this.state.name}
                onChange={(e) => this.setState({name: e.target.value})}
              />
          </div>


          <div className="p-field">
              <label htmlFor="email">Email (optional)</label>
              <InputText
                value={this.state.email}
                onChange={(e) => this.setState({email: e.target.value})}
              />
          </div>

          <div className="p-field">
              <label htmlFor="message">Message</label>
              <InputTextarea
                id="message"
                rows={5}
                cols={30}
                value={this.state.message}
                onChange={(e) => this.setState({message: e.target.value})}
              />
          </div>
        </div>

        <div className="p-field">
          <Captcha siteKey="6Le1vqkUAAAAACYDU-ZxvwSymGJK7IKMQiP4Pelm" onResponse={this.reCaptchaResponse} />
        </div>

        <div className="p-field">
          <Button
            label="Send"
            className="space-right p-button-secondary"
            icon="pi pi-envelope"
            disabled={!this.state.recaptcha || !this.state.message}
            onClick={this.send}
          />
        </div>

      </div>
    )
  }
}

export default Contact;
