import React from 'react';

import {ToggleButton} from 'primereact/togglebutton';

export default class FieldText extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      field: this.props.field,
      isNumeric: this.props.field.type === 'numeric'
    }
  }

  render() {
    return (
      <div>
        <div>
          <ToggleButton style={{minWidth:'110px', minHeight: '2em', marginRight: '5px'}}
            onIcon="pi pi-exclamation-triangle" offIcon="pi pi-question-circle"
            onLabel="Required" offLabel="Optional"
            checked={this.props.field.required}
            onChange={(e) => this.props.updateField({required: e.value})}
            />
          <ToggleButton style={{minWidth:'110px', minHeight: '2em'}}
            onIcon="" offIcon=""
            onLabel="Numeric" offLabel="Text"
            checked={this.state.isNumeric}
            onChange={(e) => {
              this.setState({isNumeric: e.value})
              this.props.updateField({type: (e.value ? 'numeric': 'text')})
            }}
            />
        </div>
      </div>
    )
  }

}
