import React from 'react';

import { InputText } from 'primereact/inputtext';


export default class EditFieldGPS extends React.PureComponent {

  constructor(props) {
    super(props);

    let latitude = null;
    let longitude = null;
    if (this.props.value) {
      longitude = this.props.value[0];
      latitude = this.props.value[1];
    }

    this.state = {
      latitude: latitude,
      longitude: longitude,
    }
  }

  onChange(lon, lat) {

    this.setState({longitude: lon, latitude: lat});
    // call parent onChange method
    this.props.onChange(
      this.props.field.uuid, [
        parseFloat(lon),
        parseFloat(lat),
        0,  // altitude is hard coded to 0
        15, // accuracy is hard coded to 15
      ]
    )
  }

  render() {

    if (!this.props.field) return <div />

    return (

      <div className="">
        <div className="p-field space-right" style={{display: 'inline-block'}}>
            <label htmlFor="longitude">Longitude</label>
            <InputText
              value={this.state.longitude}
              id="longitude"
              keyfilter="num"
              onChange={(e) => this.onChange(e.target.value, this.state.latitude)}
            />
        </div>
        <div className="p-field" style={{display: 'inline-block'}}>
            <label htmlFor="latitude">Latitude</label>
            <InputText
              value={this.state.latitude}
              id="latitude"
              keyfilter="num"
              onChange={(e) => this.onChange(this.state.longitude, e.target.value)}
            />
        </div>

      </div>
    )
  }

}
