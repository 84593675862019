import React from 'react';

import { Calendar } from 'primereact/calendar';

import moment from 'moment'


export default class EditFieldDate extends React.PureComponent {

  constructor(props) {
    super(props);

    // convert incoming date string to date object for the Calendar widget
    this.state = {
      value: moment(this.props.value).toDate(),
    }
  }

  /*
  convert the incoming date object into a timezone-free string of format YYYY-MM-DD
  */
  onChange(fieldUuid, dateValue) {
    // convert date to string
    let stringValue = moment(dateValue).format('YYYY-MM-DD');
    // call parent onChange method
    this.props.onChange(fieldUuid, stringValue);
  }


  render() {
    return (
      <div>
        <Calendar
          value={this.state.value}
          onChange={(e) => this.onChange(this.props.field.uuid, e.target.value)}
          dateFormat="yy-mm-dd"

          />
      </div>
    )
  }

}
