import React from 'react';

import {ToggleButton} from 'primereact/togglebutton';
import {InputText} from 'primereact/inputtext';
import {OrderList} from 'primereact/orderlist';
import {Button} from 'primereact/button';

import _ from 'lodash';

export default class FieldChoice extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      field: this.props.field,
      newValue: ''
    }
    // this is used to extract the state from the OrderList coz sadly
    // its not exposed as a callback when a selection is made, for the
    // delete button
    this.OrderedListRef = React.createRef();
  }

  /** callback for the OrderList when reordering */
  onOrderChange = (event) => {
    this.props.updateField({options: event.value});
  }

  /** add an option to the list */
  addOption = () => {
    if (!this.state.newValue) return;
    var options = this.props.field.options || [];
    options.push(this.state.newValue);
    // call our parent field's update function
    this.props.updateField({options: options});
    // clear the textbox for the next use
    this.setState({newValue: ''})
  }

  /** delete the selected options from the options list */
  deleteOption = () => {
    // Get existing options
    var options = this.props.field.options || [];
    // Extract the current selection from the OrderList,
    // (this is described here):
    // https://stackoverflow.com/questions/27864951/how-to-access-childs-state-in-react
    const orderedList = this.OrderedListRef.current;
    var selection = orderedList.state.selection;
    if (selection.length === 0) return;

    // The ...orderList.. syntax means spread the selection list out as a list
    // of arguments to the without() function.
    // Here we remove the selection from the existing options
    options = _.without(options, ...orderedList.state.selection);
    // Update state with the new options
    this.props.updateField({options: options});
  }

  render() {
    return (
      <div>
        <div>
          <ToggleButton style={{minWidth:'110px', minHeight: '2em', marginRight: '5px'}}
            onIcon="pi pi-exclamation-triangle" offIcon="pi pi-question-circle"
            onLabel="Required" offLabel="Optional"
            checked={this.props.field.required}
            onChange={(e) => this.props.updateField({required: e.value})}
            />
          <ToggleButton style={{minWidth:'110px', minHeight: '2em'}}
            onIcon="" offIcon=""
            onLabel="Multiple" offLabel="Single"
            checked={this.props.field.type === 'mchoice'}
            onChange={(e) => this.props.updateField({type: e.value ? 'mchoice': 'choice'})}
            />
        </div>

        <div style={{padding: '5px 0px 5px 0px'}}>
          <InputText style={{marginRight: '5px'}} value={this.state.newValue}
            onChange={(e) => this.setState({newValue: e.target.value})} />
          <Button label=""  icon="pi pi-plus" style={{marginRight: '5px'}} onClick={this.addOption} />
          <Button label=""  icon="pi pi-trash" style={{marginRight: '5px'}} onClick={this.deleteOption} />
        </div>

        <div className="p-grid">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <OrderList
              header=""
              responsive={true}
              value={this.props.field.options}
              onChange={this.onOrderChange}
              ref={this.OrderedListRef} />
          </div>
        </div>

      </div>
    )
  }

}
