import React from 'react';

import { MultiSelect } from 'primereact/multiselect';


export default class EditFieldMChoice extends React.PureComponent {

  constructor(props) {
    super(props);

    // convert incoming field options into a list of dicts suitable for the MultiSelect widget
    let options = [];
    if (this.props.field.options) {
      this.props.field.options.forEach((item, index) => {
        // add identical 'label' and 'value' columns
        options.push({value: item, label: item});
      });
    }

    this.state = {
      options: options,
      values: this.props.value
    }
  }

  onChange(fieldUuid, values) {
    // update local list
    this.setState({values: values});
    // call parent onChange method
    this.props.onChange(fieldUuid, values);
  }

  render() {

    if (!this.props.field) return <div />

    return (
      <div>
        <MultiSelect
          value={this.state.values}
          options={this.state.options}
          display='chip'
          onChange={(e) => this.onChange(this.props.field.uuid, e.value)}
        />
      </div>
    )
  }

}
