import React from 'react';

import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';

import {Draggable, Droppable} from 'react-beautiful-dnd';

import _ from 'lodash';

import drag_handle from '../static/drag_handle.png';

import {Dialog} from 'primereact/dialog';
import InputTransparent from './InputTransparent';
import Field from './Field';

export default class Page extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: this.props.page
    }
  }

  componentDidMount(){
    this.setState({
      page: this.props.page
    })
  }

  updatePageCollapsed = value => {
    const page = _.clone(this.state.page)
    page.collapsed = value;
    this.setState({page: page})
    //propagate to survey state
    this.props.onPageChange(page)
  }

  /** update survey label */
  updatePageLabel = value => {
    const page = _.clone(this.state.page)
    page.label = value;
    this.setState({page: page})
    //propagate to survey state
    this.props.onPageChange(page)
  }

  onDeletePage = () => {
    this.setState({displayModalDelete: false})

    // mark the page as deleted, this will start the collapse animation
    this.setState({deleting: true})

    // set a timeout for when the animation has finished (300ms), to remove the
    // (now invisible) field from the state
    setTimeout(() => {
      this.props.onPageDelete(this.state.page)
    }, 300);  // 300ms must match the css animation duration
  }

  getDeleteDialog = () => {
    return <Dialog
      header="Delete page"
      visible={this.state.displayModalDelete}
      style={{minWidth: '50vw'}}
      closeOnEscape={true}
      onHide={() => this.setState({displayModalDelete: false})}
      >
      Are you sure ?
      <div style={{textAlign: 'right'}}>
        <Button label="Delete" icon="" onClick={() => this.onDeletePage()} />
        <Button label="Cancel" icon=""
          style={{marginLeft: "5px"}}
          className="p-button-secondary"
          onClick={() => this.setState({displayModalDelete: false})}
          />
      </div>
    </Dialog>

  }

  render() {
    return (
      <Draggable
        draggableId={this.props.page.uuid}
        index={this.props.index}
      >
        {(provided) => (
          <div className={'page ' + (this.state.deleting===true ? "delete-field"  : "")}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div style={{position: 'relative'}}>
            <div className="p-d-flex  p-ai-center p-flex-wrap">
              <Button
                icon={this.props.page.collapsed ? 'pi pi-angle-right': 'pi pi-angle-down'}
                className="p-button-rounded p-button-secondary"
                onClick={(e) => this.updatePageCollapsed(!this.state.page.collapsed)}
                />

              <InputTransparent
                value={this.state.page.label}
                onChange={(e) => this.updatePageLabel(e.target.value)}
                placeholder="Page Title"
                className="transparent-field"
              />

              <div style={{position: 'absolute', right: '15px'}}>
                <img src={drag_handle} alt="text" className='fieldIcon p-field' style={{width: '2rem'}} />
                <Button
                  icon="pi pi-trash"
                  className="p-button-secondary"
                  onClick={(e) => this.setState({displayModalDelete: true})}
                  />
              </div>
            </div></div>

            {/*  <!-- collapsable field part --> */}
            <Droppable droppableId={this.props.page.uuid} type="field">
              {provided => (

                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <Panel header=""
                    toggleable
                    collapsed={this.props.page.collapsed}
                    onToggle={(e) => {}}>
                    {
                      this.props.fields.map((field, index) =>
                        <Field
                          key={field.uuid}
                          field={field}
                          index={index}
                          onFieldChange={this.props.onFieldChange}
                          onFieldDelete={this.props.onFieldDelete}
                        />
                      )
                    }
                  </Panel>
                  { this.getDeleteDialog() }

                  {provided.placeholder}

                </div>

              )}
            </Droppable>
          </div>
        )}
      </Draggable>
    )
  }
}
