import React from 'react';

import { Sidebar } from 'primereact/sidebar';

import NavMenu from './NavMenu';
import logo from '../static/logo.png';
import Api from '../utils/Api';


class NavBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sideBarVisible: false
    }

    this.Api = new Api();
    this.handleLogout = this.handleLogout.bind(this);
  }


  handleLogout(event) {
    this.Api.auth.logout()
      .then((res) => {
        this.props.onLogout();
      },function(error){
        console.log(error)
      });
  }

  doNav(url) {
    this.setState({sideBarVisible: false});
    window.location.href = url;
  }

  render() {

    return (
      <div className="layout-topbar p-grid p-nogutter">
        <Sidebar className="sidebar"
          visible={this.state.sideBarVisible}
          position="right"
          onHide={() => this.setState({sideBarVisible: false})}
          showCloseIcon={false}
          blockScroll={true}
          appendTo={document.body}
          >

          <div className='p-grid p-justify-center p-dir-col'>

            <NavMenu onClick={(e) => this.doNav('#/home')} title='Home' condition={true} />
            <NavMenu onClick={(e) => this.doNav('#/about')} title='About' condition={true} />
            <NavMenu onClick={(e) => this.doNav('#/contact')} title='Contact' condition={true} />
            <NavMenu onClick={(e) => this.doNav('#/create')} title='Create' condition={true} />
            <NavMenu onClick={(e) => this.doNav('#/analyze')} title='Analyze' condition={true} />
            <NavMenu onClick={(e) => this.doNav('#/clients')} title='Clients' condition={this.props.parentState.user} />
            <NavMenu onClick={(e) => this.doNav('#/login')} title='Login' condition={!this.props.parentState.user} />
            <NavMenu onClick={this.handleLogout}
              title={
                'Logout ' + (this.props.parentState.user?.username ? `(${this.props.parentState.user.username})` : '')
              }
              condition={this.props.parentState.user} />
            </div>

        </Sidebar>


        <div className="p-col-fixed p-col-align-center" >
          <a href='/#/'><img className='logo' alt='logo' src={logo} /></a>
        </div>
        <div className="p-col-fixed p-col-align-center logo-text" >
          <a href='/#/'>TapSee</a>
        </div>

        <div className="p-col p-col-align-center">
          <div className="p-grid p-justify-end p-nogutter" style={{paddingRight: '10px'}}>

            <NavMenu href='#/home'  title='Home' condition={true} className="navmenu-top-button" />
            <NavMenu href='#/about' title='About' condition={true} className="navmenu-top-button" />
            <NavMenu href='#/contact' title='Contact' condition={true} className="navmenu-top-button" />
            <NavMenu href='#/privacy' title='Privacy' condition={true} className="navmenu-top-button" />
            <NavMenu href='#/create' title='Create' condition={true} className="navmenu-top-button" />
            <NavMenu href='#/analyze' title='Analyze' condition={true} className="navmenu-top-button" />
            <NavMenu href='#/clients' title='Clients' condition={this.props.parentState.user}  className="navmenu-top-button" />
            <NavMenu href='#/login' title='Login' condition={!this.props.parentState.user}  className="navmenu-top-button" />
            <NavMenu onClick={this.handleLogout}
              title={
                'Logout ' + (this.props.parentState.user?.username ? `(${this.props.parentState.user.username})` : '')
              }
              condition={this.props.parentState.user}
              className="navmenu-top-button" />

            <NavMenu
              onClick={(e) => this.setState({sideBarVisible: true})}
              title={<i className="pi pi-bars"></i>}
              condition={true}
              className="sidebar-button-menu"/>


            </div>


        </div>
      </div>
    )
  }
}

export default NavBar;
