import React from 'react';

import { v4 as uuidv4 } from 'uuid';

import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';

import _ from 'lodash';

export default class FieldChoice extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      field: this.props.field,
      colHeaders: this.props.field.config.colHeaders,
      rowHeaders: this.props.field.config.rowHeaders,
      uuids: this.props.field.config.uuids,

      // these are for the animations on adding / removing cols / rows
      newCol: null,
      delCol: null
    }

  }

  addColumn = index => {
    // add the new col to the list of columns
    var colHeaders = this.state.colHeaders;
    colHeaders.splice(index + 1, 0, "col title");

    // add the new col to all the cells
    var uuids = this.state.uuids;
    _.forEach(uuids, function(row) {
      row.splice(index + 1, 0, uuidv4());
    });

    this.setState({
      // declare the new col id in order to add the 'newCol' class to give the css animation
      newCol: index+1,
      delCol: null,
      colHeaders: colHeaders,
      uuids: uuids
    });

    // Update state with the new cols
    this.props.updateField({colHeaders: colHeaders, uuids: uuids});
  }

  removeColumn = index => {
    if (this.state.colHeaders.length === 1) return;

    // mark the col index as deleted, this will start the collapse animation
    this.setState({delCol: index, newCol: null})

    // use the delCol flag as an indication that the animation is currently
    // running, don't let the user delete 2 cols that quickly
    if (this.state.delCol !== null) {
      return;
    }

    // set a timeout for when the animation has finished (300ms), to remove the
    // (now invisible) column from the state
    setTimeout(() => {
      var colHeaders = this.state.colHeaders;
      colHeaders.splice(index, 1);

      var uuids = this.state.uuids;
      _.forEach(uuids, function(row) {
        row.splice(index, 1);
      });

      this.setState({
        colHeaders: colHeaders,
        uuids: uuids,
        // reset delCol ready for next use
        delCol: null
      });
      // Update state with the new cols
      this.props.updateField({colHeaders: colHeaders, uuids: uuids});

    }, 300);  // 300ms must match the css animation duration

  }

  addRow = index => {
    var rowHeaders = this.state.rowHeaders;
    rowHeaders.splice(index + 1, 0, "row title");
    this.setState({rowHeaders: rowHeaders});

    var uuids = this.state.uuids;
    var rowData = [];
    _.times(this.state.colHeaders.length, function() {
       rowData.push(uuidv4());
    });
    uuids.splice(index + 1, 0, rowData)
    this.setState({uuids: uuids});
    // Update state with the new rows
    this.props.updateField({rowHeaders: rowHeaders, uuids: uuids});
  }

  removeRow = index => {
    if (this.state.rowHeaders.length === 1) return;

    var rowHeaders = this.state.rowHeaders;
    rowHeaders.splice(index, 1);
    this.setState({rowHeaders: rowHeaders});

    var uuids = this.state.uuids;
    uuids.splice(index, 1)
    this.setState({uuids: uuids});
    // Update state with the new rows
    this.props.updateField({rowHeaders: rowHeaders, uuids: uuids});
  }

  /**
  onChange callback for column header value change
  */
  updateColHeader(header, index) {
    var colHeaders = this.state.colHeaders;
    colHeaders[index] = header;
    this.setState({colHeaders: colHeaders});
    this.props.updateField({colHeaders: colHeaders});
  }

  /**
  column header widgets
  */
  renderColHeader(header, index)  {
    return (
      <th key={this.state.colHeaders.length+':'+index} className={
          'grid-header ' +
            (this.state.newCol===index ? "newCol "  : "") +
            (this.state.delCol===index ? "oldCol "  : "")
        }>
        <div>
          <Button icon="pi pi-plus" className="grid-field-button"
            style={{marginRight: '3px'}} onClick={() => this.addColumn(index)}/>
          <Button icon="pi pi-minus" className="grid-field-button" onClick={() => this.removeColumn(index)}/>
        </div>

        <InputText
          value={header}
          onChange={(e) => this.updateColHeader(e.target.value, index)}
          className="transparent-grid-header-field"
          placeholder="Col header"
        />
      </th>
    );
  }

  /**
  onChange callback for row header value change
  */
  updateRowHeader(header, index) {
    var rowHeaders = this.state.rowHeaders;
    rowHeaders[index] = header;
    this.setState({rowHeaders: rowHeaders});
    this.props.updateField({rowHeaders: rowHeaders});
  }

  /**
  row header widgets
  */
  renderRowHeader(rowHeader, index) {
    return (
      <td>
      <InputText
        value={rowHeader}
        onChange={(e) => this.updateRowHeader(e.target.value, index)}
        className="transparent-grid-header-field"
        placeholder="Row header"
      />
      <Button icon="pi pi-plus" className="grid-field-button"
        style={{marginRight: '3px'}} onClick={() => this.addRow(index)}/>
      <Button icon="pi pi-minus" className="grid-field-button" onClick={() => this.removeRow(index)}/>
      </td>
    )
  }

  /**
  normal data cells widgets
  */
  renderDataCells(rowData, rowIndex)  {
    return (
      rowData.map((cell, colIndex) => (
        <td className="grid-data-cell" key={'r'+rowIndex + 'c' + colIndex}></td>
      ))
    )

  }

  renderRow(rowHeader, rowIndex)  {
    return (
      <tr key={rowIndex}>
        {this.renderRowHeader(rowHeader, rowIndex)}
        {this.renderDataCells(this.state.uuids[rowIndex], rowIndex)}
        {/**
        this empty td will expand to fill remaining space when there are not
         many columns in the grid
        */}
        <td></td>
      </tr>
    );
  }

  render() {


    return (
      <div>

        <div className="grid-field-wrapper">
          <table className="grid-field">
            <thead>
              <tr>
                <th className="grid-header"></th>
                {
                  this.state.colHeaders.map((item, index) => (
                    this.renderColHeader(item, index)
                  ))
                }
                { /* this cell to consume space when not many cols are added */ }
                <th className=""></th>
              </tr>
            </thead>
            <tbody>
                {
                  this.state.rowHeaders.map((item, index) => (
                    this.renderRow(item, index)
                  ))
                }
            </tbody>
          </table>
        </div>

      </div>
    )
  }

}
