import React from 'react';

import onClickOutside from 'react-onclickoutside'

import icon_add_page from '../static/add_page.png';
import icon_add_text from '../static/add_text.png';
import icon_add_choice from '../static/add_choice.png';
import icon_add_mchoice from '../static/add_mchoice.png';
import icon_add_date from '../static/add_date.png';
import icon_add_gps from '../static/add_gps.png';
import icon_add_grid from '../static/add_grid.png';
import icon_add_photo from '../static/add_photo.png';

class AddTools extends React.Component {



  handleClickOutside = () => {
    if (this.props.close) {
      this.props.close();
    }
  }

  render() {
    return (
      <div>
        <div style={{display: 'inline-block'}}>
          <img src={icon_add_page} alt="add page" className='tool' onClick={() => this.props.parent.addPage()}/>
        </div>
        <div style={{display: 'inline-block'}}>
          <img src={icon_add_text} alt="add text" className='tool' onClick={() => this.props.parent.addField('text')}/>
        </div>
        <div style={{display: 'inline-block'}}>
          <img src={icon_add_choice} alt="add choice" className='tool' onClick={() => this.props.parent.addField('choice')} />
        </div>
        <div style={{display: 'inline-block'}}>
          <img src={icon_add_mchoice} alt="add mchoice" className='tool' onClick={() => this.props.parent.addField('mchoice')} />
        </div>
        <div style={{display: 'inline-block'}}>
          <img src={icon_add_date} alt="add date" className='tool' onClick={() => this.props.parent.addField('date')} />
        </div>
        <div style={{display: 'inline-block'}}>
          <img src={icon_add_gps} alt="add gps" className='tool' onClick={() => this.props.parent.addField('gps')} />
        </div>
        <div style={{display: 'inline-block'}}>
          <img src={icon_add_grid} alt="add grid" className='tool' onClick={() => this.props.parent.addField('grid')} />
        </div>
        <div style={{display: 'inline-block'}}>
          <img src={icon_add_photo} alt="add photograph" className='tool' onClick={() => this.props.parent.addField('photo')} />
        </div>
      </div>
    )
  }

}

export default onClickOutside(AddTools)
