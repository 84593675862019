import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';

import _ from 'lodash';

import Api from '../utils/Api';
import Survey from './Survey';
import AddTools from './AddTools';

import {isAdmin} from '../utils/utils.js';

import icon_add_add from '../static/add_add.png';

class Create extends React.Component {


  constructor(props) {
    super(props);
    this.Api = new Api();

    this.state = {
      survey: {label: '', code: this.props.match.params.code || null},
      pages: [],
      fields: [],

      displayModalSaved: false,
      displayModalOpen: false,
      openCode: ''
    }

  }

  componentWillUnmount() {
    // stop listening for route changes for analysing new surveys
    this.unListenRouteChange();
  }

  componentDidMount() {

      // listen for changes to the route to trigger a new survey analyze
      this.unListenRouteChange = this.props.history.listen((location, action) => {
        // if action is REPLACE then this was a form submit with manual url change, no need to
        // update state and results here
        if (action === 'REPLACE') return;

        if (this.props.match.params.code) {
            this.fetchSurvey(this.props.match.params.code);
        }
      });

      if (this.state.survey && this.state.survey.code) {
        this.fetchSurvey(this.state.survey.code);
      }

      // get the user started on a new survey
      this.resetState();


  }

  /*
  Reset the state to starting point, and create the first field(s) for kickoff
  */
  resetState = () => {
    this.setState({
      survey: {label: '', code: null},
      pages: [],
      fields: [],
      displayModalSaved: false,
      displayModalOpen: false,
      openCode: ''
    });

    // get the user started on a new survey
    this.addPage(function() {
      // page is ready, add a text field to it
      this.addField('text')
    });
  }

  addPage = (callback) => {
    const pages = _.clone(this.state.pages);
    var newPage = {
      uuid: uuidv4(),
      label: '',
      collapsed: false
    }
    pages.push(newPage)
    // after setting the state call the callback to let the sender know its been done
    this.setState({pages: pages, showMenu: false}, callback)
  }

  addField = (type) => {

    // if there's no pages in the survey add one
    if (this.state.pages.length === 0) {
      this.addPage(() => {
        this.addField(type)
      });
      return;
    }

    const fields = _.clone(this.state.fields);

    var newField = {
      uuid: uuidv4(),
      label: '',
      description: '',
      type: type,
      pageUuid: this.state.pages[this.state.pages.length-1].uuid,

      required: false,
      // this is used for the new field color animation
      new: true
    }

    if (type === 'choice') {
      newField.options = [];
    } else if (type === 'grid') {
      newField.config = {
        colHeaders: ['col1', 'col2'],
        rowHeaders: ['row1', 'row2'],
        uuids: [[uuidv4(), uuidv4()], [uuidv4(), uuidv4()]],
      }
    }

    fields.push(newField)
    this.setState({fields: fields, showMenu: false})
  }

  /**
  update the fields on the survey object itself
  */
  onSurveyChange = (survey) => {
    this.setState({survey: survey});
  }

  /**
  update all pages, called following a dnd operation
  */
  onPagesChange = (pages, callback) => {
    this.setState({pages: pages}, callback);
  }

  /**
  update all fields, called following a dnd operation
  */
  onFieldsChange = (fields) => {
    this.setState({fields: fields});
  }

  /**
  update info in a given page, assumes that the page.uuid never changes.
  */
  onPageChange = (page) => {
    // clone pages state
    const pages = _.clone(this.state.pages);
    // determine the index for the page to change
    var pageIndex = _.findIndex(pages, function(candidatePage) {
       return page.uuid === candidatePage.uuid;
    });
    // make the local change
    pages[pageIndex] = page;
    // update component state
    this.setState({
      pages: pages
    })
  }

  /**
  delete the given page
  */
  onPageDelete = (page) => {
    const pages = _.clone(this.state.pages);
    const fields = _.clone(this.state.fields);

    // filter to only allow the pages with a uuid different from the field
    // we want to delete
    var pagesWithoutDeleted = _.filter(pages, function(candidatePage) {
      return candidatePage.uuid !== page.uuid;
    });

    // now delete any fields associated with the deleted page
    var fieldsWithoutDeleted = _.filter(fields, function(candidateField) {
      return candidateField.pageUuid !== page.uuid;
    });

    // update component state
    this.setState({
      pages: pagesWithoutDeleted,
      fields: fieldsWithoutDeleted
    })
  }

  /**
  delete the given field, assumes that the field.uuid never changes.
  */
  onFieldDelete = (field) => {
    // clone fields state
    const fields = _.clone(this.state.fields);

    // filter to only allow the fields with a uuid different from the field
    // we want to delete
    var fieldsWithoutDeleted = _.filter(fields, function(candidateField) {
      return candidateField.uuid !== field.uuid;
    });

    // update component state
    this.setState({
      fields: fieldsWithoutDeleted
    })
  }

  /**
  update info in a given field, assumes that the field.uuid never changes.
  */
  onFieldChange = (field) => {
    // clone fields state
    const fields = _.clone(this.state.fields);
    // determine the index for the field to change
    var fieldIndex = _.findIndex(fields, function(candidateField) {
       return field.uuid === candidateField.uuid;
    });
    // make the local change
    fields[fieldIndex] = field;
    // update component state
    this.setState({
      fields: fields
    })
  }

  /** close the add-tools overlay */
  onCloseAddTools = () => {
    this.setState({showMenu: false})
  }

  /** show the add-tools overlay */
  onShowAddTools = () => {
    this.setState({showMenu: true})
  }

  /** POST survey payload to server */
  onSave = () => {
    var jsonPayload = Survey.serialize(this.state);

    this.Api.surveys.save(jsonPayload).then((res) => {
      console.log('successful save returned:');
      console.log(res);

      if (!res) {
        this.toast.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Unidentified error occurred',
          style: {'white-space': 'pre-wrap'}
        });
        return;
      }

      var survey = Survey.deserialize(res);
      var surveyCode = survey.survey.code;
      this.setState(_.extend(survey, {displayModalSaved: true}));

      // if the url is not already updated to include the survey code then do it here
      var newUrl = "/create/" + surveyCode + "/";
      if (this.props.history.location.pathname !== newUrl) {
        this.props.history.replace(newUrl)
      }


    }, (error) => {
      var message = error.message
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        style: {'white-space': 'pre-wrap'}
      });
    });
  }

  /**
  show open survey dialog
  */
  onOpen = () => {
    this.setState({openCode: ''})
    this.setState({displayModalOpen: true})
  }

  /*
  Semi-complex logic for disabling the survey save if the survey is demo and the
    user is not admin
  */
  saveDisabled = () => {
    if (!this.state.survey.label) {
      return true;
    }

    if (this.state.survey.code === 'aaaaaa' && !isAdmin()) {
      return true;
    }

    return false;
  }

  /**
  fetch a given survey from the server for edition
  */
  fetchSurvey = (surveyCode) => {

    this.resetState()

    this.Api.surveys.fetch(surveyCode).then((res) => {

      // sucessful fetch, load the page here
      var survey = Survey.deserialize(res);
      var surveyCode = survey.survey.code;
      this.setState(survey);

      // if the url is not already updated to include the survey code then do it here
      var newUrl = "/create/" + surveyCode + "/";
      if (this.props.history.location.pathname !== newUrl) {
        this.props.history.replace(newUrl)
      }

    }).catch((err) => {


      // finally ensure the url and page are reset
      var newUrl = "/create/";
      if (this.props.history.location.pathname !== newUrl) {
        this.props.history.replace(newUrl)
      }
      this.resetState();

      // there was an error, show a message
      var message = err.message
      if (err && err.status === 404) {
        message = "Survey code not found";
      }

      // this.toast can be null here if there was an error rendering the survey, e.g. bad serialization
      if (this.toast) {
        this.toast.show({
          severity: 'error',
          summary: 'Error',
          detail: message,
          style: {'white-space': 'pre-wrap'}
        });
      }

    })
  }

  render() {
    return (
      <div>

        <Toast ref={(el) => this.toast = el} />

        <Dialog
          header="Survey saved !"
          visible={this.state.displayModalSaved}
          style={{minWidth: '50vw', 'textAlign': 'center'}}
          onHide={() => this.setState({displayModalSaved: false})}
          dismissableMask={true}>
            <p>
              Your survey code is: <span className="survey-code">{this.state.survey.code}</span>
            </p>

            <p style={{'paddingTop': '15px'}}>
              If you have the <a href='https://play.google.com/store/apps/details?id=org.tapsee' rel="noopener noreferrer" target='_blank'>
                TapSee mobile application</a> already installed, you can scan the QR code to download your survey immediately.
            </p>
            <div>
              <img className="qr-code" alt="qr-code" src={this.Api.surveys.getQRURL(this.state.survey.code)} />
            </div>

            <div style={{textAlign: 'right'}}>
              <Button label="Close" icon="pi pi-times" onClick={() => this.setState({displayModalSaved: false})} />
            </div>
        </Dialog>

        <Dialog
          header="Open survey"
          visible={this.state.displayModalOpen}
          style={{minWidth: '50vw'}}
          onHide={() => this.setState({displayModalOpen: false})}
          >

          <span className="p-float-label dialog-field">
            <InputText
              value={this.state.openCode}
              autoFocus={true}
              onChange={(event) => this.setState({openCode: event.target.value})}
              onKeyDown={(event) => {
                  // enter key submits when code has 6 chars
                  if (event.key === 'Enter' && event.target.value.length === 6) {
                    this.fetchSurvey(this.state.openCode)
                  }
                }
              }
              />
            <label >Survey code</label>
          </span>
          <div style={{textAlign: 'right'}}>
            <Button label="Open" disabled={this.state.openCode.length !== 6} icon="pi pi-download" onClick={() => this.fetchSurvey(this.state.openCode)} />
          </div>

        </Dialog>


        { /** floating add menu on bottom-right */ }
        <div className='add-tools-button'>
            <div className='add-tools-button-plus'>
              <img src={icon_add_add} alt="Add field" onClick={this.onShowAddTools} style={{'width': '60px'}} />
            </div>
            <div className='add-tools-menu'
              style={{'display': this.state.showMenu === true ? "inline": "none"}}>
                <AddTools parent={this} close={this.onCloseAddTools}/>
            </div>
        </div>

        <div className='table-title'>
          <label style={{display: this.state.survey.code !== null ? "none": "inline"}}>
            Create
          </label>
          <label style={{display: this.state.survey.code !== null ? "inline": "none"}}>
            Edit: {this.state.survey.label} ( {this.state.survey.code} )
          </label>
        </div>

        <div className="p-grid">

          <div className="add-tools-main-wrapper p-col-1 p-sm-1 p-md-2 p-lg-2">
            <div className="add-tools-main">
              <AddTools parent={this}  />
            </div>
          </div>

          <div  className="p-col survey">


          <div>
            <Button label="Save" icon="pi pi-save" className="space-right p-button-secondary"
              onClick={() => this.onSave()} disabled={this.saveDisabled()} />
            <Button label="Open" icon="pi pi-folder-open" className="space-right p-button-secondary"
              onClick={() => this.onOpen()} />
          </div>

            <Survey
              survey={this.state.survey}
              pages={this.state.pages}
              fields={this.state.fields}
              onPageChange={this.onPageChange}
              onFieldChange={this.onFieldChange}
              onFieldDelete={this.onFieldDelete}
              onPagesChange={this.onPagesChange}
              onPageDelete={this.onPageDelete}
              onFieldsChange={this.onFieldsChange}
              onSurveyChange={this.onSurveyChange}
              onSurveySave={this.onSurveySave}
            />
            </div>
        </div>

      </div>
    )
  }
}

export default Create;
