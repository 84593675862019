import React from 'react';

import { InputText } from 'primereact/inputtext';

import _ from 'lodash';


export default class EditFieldGrid extends React.PureComponent {

  constructor(props) {
    super(props);


    this.state = {
      values: this.props.value
    }

    this.renderDataCells = this.renderDataCells.bind(this);
    this.renderRow = this.renderRow.bind(this);

  }


  /*
  update a single gridfield value and rebuild the field state and send it up to parent form
  */
  onChange(gridFieldUuid, value) {
    // get a deep copy of the pages to avoid modifying the local props
    const newStateValues = _.cloneDeep(this.state.values) || {};
    newStateValues[gridFieldUuid] = value;
    this.setState({values: newStateValues});

    this.props.onChange(this.props.field.uuid, newStateValues);
  }


  /*
  render a grid field row by printing the row header then looping over the row result values
  */
  renderRow(field, rowHeader, rowIndex)  {
    // get the list of (rows) containing the list of cols of uuids
    const gridFieldUuids = field.config.uuids;

    return (
      <tr key={rowIndex}>
        <td className="map-grid-field-header">{rowHeader}</td>
        {
          this.renderDataCells(
            field.uuid, gridFieldUuids[rowIndex]
          )
        }
      </tr>
    );
  }


  /**
  output normal data cell (i.e. all rows after the first row header cell)
  */
  renderDataCells(fieldUuid, rowUuids)  {
    return (
      rowUuids.map((gridFieldUuid, colIndex) => {
        // safely obtain value for grid cell uuid from field value dict
        // const value = getNested(this.props.value, gridFieldUuid)
        let value = '';
        if (this.state.values) {
          value = this.state.values[gridFieldUuid];
        }
        return (
          <td className="map-grid-data-cell" key={gridFieldUuid}>
            <InputText
              value={value}
              onChange={(e) => this.onChange(gridFieldUuid, e.target.value)}
            />
          </td>
        )
      })
    );
  }


  render() {

    const field = this.props.field;
    let colHeaders = field.config?.colHeaders;
    let rowHeaders = field.config?.rowHeaders;
    let uuids = field.config?.uuids;

    // problem with data structure, giving up
    if (!colHeaders || !rowHeaders || !uuids) {
      console.warn('invalid gridfile structure');
      return <div/>
    }

    return (
      <table className="map-grid-field">


        <thead>
          <tr>
            <th>{/* empty header column */}</th>
            {
              // for the first table row, loop over column headers
              colHeaders.map((item, index) => (
                <th key={'col' + index}>{item}</th>
              ))
            }
            </tr>
        </thead>
        <tbody>
            {
              // now loop over rowHeaders as a means of looping over (down) rows
              rowHeaders.map((item, index) => (
                // call renderRow which will output row header and loop over columns
                this.renderRow(field, item, index)
              ))
            }
        </tbody>
      </table>
    )

  }

}
