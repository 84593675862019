import React from 'react';


class NavMenu extends React.Component {

  render() {

    let props = this.props;

    if (props.condition) {
      return (
        <div className={'p-component navmenu center ' + (this.props.className || '')}>
          <a href={props.href} onClick={props.onClick} style={{cursor:'pointer'}}> {props.title} </a>
        </div>
      )
    } else {
      return null;
    }

  }
}

export default NavMenu;
