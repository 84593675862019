import React from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Dialog} from 'primereact/dialog';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';
import {TabView, TabPanel} from 'primereact/tabview';
import {Toast} from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';

import _ from 'lodash';

import Api from '../utils/Api';
import Survey from './Survey';
import DialogEditResult from './DialogEditResult';
import ModalImage from './ModalImage';
import AnalyzeMap from './AnalyzeMap';

import {roundNumber, getNested, getUser} from '../utils/utils';

class Analyze extends React.PureComponent {

  constructor(props) {
    super(props);
    this.Api = new Api();

    this.state = {
      // TODO: remove this for production !
      surveyCode: this.props.match.params.code || 'aaaaaa',
      survey: null,
      mapEnabled: false,
      csvPhotos: false,
      csvSeparator: 'comma',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      modalDownloadCSV: false,
      loading: false,
      displayModalEditResult: false,
      displayModalDeleteResult: true,
      editResult: {}

    }

    // populate lists for CSV download form
    this.csvSeparators = ['comma', 'semi-colon', 'tab'];
    this.csvTimezones = [Intl.DateTimeFormat().resolvedOptions().timeZone, 'UTC'];

    this.listResults = this.listResults.bind(this);
    this.getToolsTemplate = this.getToolsTemplate.bind(this);
  }

  componentWillUnmount() {
    // stop listening for route changes for analysing new surveys
    this.unListenRouteChange();
  }

  componentDidMount() {

    // listen for changes to the route to trigger a new survey analyze
    this.unListenRouteChange = this.props.history.listen((location, action) => {
      // if action is REPLACE then this was a form submit with manual url change, no need to
      // update state and results here
      if (action === 'REPLACE') return;

      if (this.props.match.params.code) {
        this.setState({surveyCode: this.props.match.params.code});
        this.listResults(this.state.surveyCode);
      }
    });

    if (this.state.surveyCode) {
      this.listResults(this.state.surveyCode);
    }

  }

  /**
   *
   * download csv
  */
  downloadCSV = (surveyCode) => {
    this.Api.surveys.csv(
      surveyCode,
      this.state.csvPhotos,
      this.state.csvSeparator,
      this.state.timezone
    );
    this.setState({modalDownloadCSV: false});
  }

  /**
   *
   * download kmz
  */
  downloadKMZ = (surveyCode) => {
    this.Api.surveys.kmz(surveyCode, true);
  }

  /**
  list results for a given survey code from the server for listing
  */
  listResults = (surveyCode) => {

    // if the url is not already updated to include the survey code then do it here
    var newUrl = "/analyze/" + surveyCode + "/";
    if (this.props.history.location.pathname !== newUrl) {
      this.props.history.replace(newUrl)
    }

    // update the state for the new survey code, and clear the current data
    this.setState({
      surveyCode: surveyCode,
      results: null,
      survey: null,
      fields: null
    })

    // first fetch the survey for the field definitions
    this.Api.surveys.fetch(surveyCode).then((res) => {
      // return the deserialized survey
      return Survey.deserialize(res);
    }).then((survey) => {


      // then fetch the results for the survey
      this.Api.results.list(surveyCode).then((response) => {

        var results = [];
        _.forEach(response, function(result) {
          let data = result.value;
          // uuid will be used for result editing
          data['uuid'] = result.uuid;
          results.push(result.value);
        });

        // set the results along with a timestamp that the AnalyzeMap component will use to detect
        // that the data has changed to refresh the map markers
        let resultsState = {
          results: results,
          resultsTimeStamp: Date.now()
        }
        // combine the 2 dicts
        this.setState({
          ...survey,
          ...resultsState
        })

      }).catch((err) => {
        this.errorHandler(err, surveyCode);
      });

    }).catch((err) => {
      this.errorHandler(err, surveyCode);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  errorHandler = (err, surveyCode) => {
    // default message
    let message = 'Unknown error';

    // if error object is a dict, try to extract info from it
    if (err.constructor === Object) {
      if (err.detail === 'expired') {
        message = 'Survey ' + surveyCode + ' has expired.'
      } else if (err.detail) {
        message = err.detail;
      }
    } else {
      // err will be a response object, try to extract the eror message
      if (err.status === 404) {
        message = 'Survey code ' + surveyCode + ' does not exist.';
      } else if (err.statusText) {
        message = err.statusText;
      }
    }

    this.toast.show({
      severity: 'error',
      summary: 'Error',
      detail: message,
      style: {'white-space': 'pre-wrap'}
    });
  }


  templateText = (rowData, column) => {
    return <div>
        {rowData[column.field]}
    </div>;
  }

  templateMChoice = (rowData, column) => {

    if (!rowData[column.field]) return '';

    // fix for email 17/7/2024
    if (!Array.isArray(rowData[column.field])) {
      rowData[column.field] = [rowData[column.field]]
    }

    return <div>
        {rowData[column.field].join(', ')}
    </div>;
  }

  /**
  The Grid template returns the data for a single grid cell, it is called with:

  - rowData containing the dict for all the Grid (for this field)
  - column dict containing keys:
    - `field`: the uuid of the entire field
    - `fieldUuid`: the uuid of the specific grid / subfield (grid cell)

    So the cell value can be found using the 2.
  **/
  templateGrid = (rowData, column) => {
    var fieldUuid = column.fieldUuid;
    var cellUuid = column.field;
    var data = getNested(rowData, fieldUuid, cellUuid);
    return <div>{data}</div>
  }

  templateGPS = (rowData, column) => {
    let gpsData = rowData[column.field];
    if (!gpsData) return '';

    if (gpsData.length > 0) {
      return <div>
        {
          roundNumber(gpsData[0], 6) + ', ' + roundNumber(gpsData[1], 6)
        }
      </div>;
    }
  }
  templatePhoto = (rowData, column) => {

    if (!rowData[column.field]) return '';

    return <div className="p-grid p-nogutter">
      {
        rowData[column.field].map((photo_uuid, i) => {
          return <div key={photo_uuid} className="p-col">
            <ModalImage
              className="modal-image-small"
              alt={photo_uuid}
              uuids={rowData[column.field]}
              myIndex={i}
          /></div>;
        })
      }
    </div>
  }

  getTemplate = (field) => {

    switch (field.type) {
      case 'text':
        return this.templateText;
      case 'choice':
        return this.templateText;
      case 'mchoice':
        return this.templateMChoice;
      case 'date':
        return this.templateText;
      case 'gps':
        return this.templateGPS;
      case 'grid':
        return this.templateGrid;
      case 'photo':
        return this.templatePhoto;
      default:
        return this.templateText;
    }
  }

  /*
  open the deleteResult dialog
  */
  deleteResult(rowData) {
    confirmDialog({
        message: 'Are you sure you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.Api.results.delete(rowData['uuid']).then((res) => {
            // return the deserialized survey
            return Survey.deserialize(res);
          }).then((result) => {
            this.toast.show({
              severity: 'success',
              summary: 'Deleted',
              detail: 'The result was deleted',
            });
            this.listResults(this.state.surveyCode);
          }).catch((err) => {
            this.errorHandler(err, this.state.surveyCode);
          });;
        },
    });

    this.setState({
      displayModalDeleteResult: true
    })
  }

  /*
  open the editResult dialog with this row as a result data
  */
  editResult(rowData) {
    this.setState({
      displayModalEditResult: true,
      editResult: rowData
    })
  }

  /*
  return the tools column button/s
  */
  getToolsTemplate(rowData, column) {
    return <div className='center'>
      <Button
        icon="pi pi-pencil"
        className="p-button-secondary space-right"
        onClick={() => this.editResult(rowData)}
        disabled={!getUser()}
      />
      <Button
        icon="pi pi-trash"
        className="p-button-secondary"
        onClick={() => this.deleteResult(rowData)}
        disabled={!getUser()}
      />
    </div>
  }

  /*
  return the tools column
  */
  getToolsColumn() {
    return <Column
      key="tools"
      headerClassName={'analyze-header'}
      header=""
      body={this.getToolsTemplate}
      headerStyle={{ width: '8rem'}}
    />;
  }

  /**
  return the <Column> object , which could be a list in the case of grid fields
  **/
  getColumn = (field) => {

    var shouldFilter = true;
    if (field.type === 'gps' || field.type === 'photo') {
      shouldFilter = false;
    }
    if (field.type !== 'grid') {
      return <Column
                key={field.uuid}
                filter={shouldFilter}
                sortable={shouldFilter}
                filterMatchMode="contains"
                headerClassName={'analyze-header'}
                field={field.uuid}
                header={field.label}
                body={this.getTemplate(field)}
              />;
    }

    // its a grid, prepare the <Column> list here
    var rowHeaders = field.config.rowHeaders;
    var colHeaders = field.config.colHeaders;
    var uuids = field.config.uuids;

    var columns = [];
    _.forEach(rowHeaders, (rowHeader, rowIndex) => {
      _.forEach(colHeaders, (colHeader, colIndex) => {
        var uuid = uuids[rowIndex][colIndex];
        columns.push(
          <Column
            key={uuid}
            filter={false}
            sortable={false}
            headerClassName={'analyze-header'}
            field={uuid} // sub / grid field (cell) uuid
            fieldUuid={field.uuid}
            header={rowHeaders[rowIndex] + ' / ' + colHeaders[colIndex]}
            body={this.getTemplate(field)}
          />
        )
      })
    })

    return columns;
  }

  render() {

    let dynamicColumns  = [];
    if (this.state.fields) {
      dynamicColumns.push(this.getToolsColumn());
      dynamicColumns = dynamicColumns.concat(
        this.state.fields.map((field,i) => {
          return this.getColumn(field);
        })
      );
    }

    return (
      <div>

        <Toast ref={(el) => this.toast = el} />

        <Dialog
          header="Download CSV"
          visible={this.state.modalDownloadCSV}
          style={{minWidth: '50vw'}}
          onHide={() => this.setState({modalDownloadCSV: false})}>

            <div className="p-field-checkbox">
              <Checkbox inputId="binary" checked={this.state.csvPhotos}
                onChange={e => this.setState({ csvPhotos: e.checked })} />
              <label htmlFor="binary">Include photos</label>
            </div>

            <div className="p-field">
            <h4>CSV separator</h4>
              <Dropdown
                value={this.state.csvSeparator}
                options={this.csvSeparators}
                onChange={e => this.setState({ csvSeparator: e.value })}
                placeholder=""
              />
            </div>

            <div className="p-field">
            <h4>Timezone</h4>
              <Dropdown
                value={this.state.timezone}
                options={this.csvTimezones}
                onChange={e => this.setState({ timezone: e.value })}
                placeholder=""
              />
            </div>

            <div style={{textAlign: 'right'}}>
              <Button label="Download" className="space-right p-button-secondary" icon="pi pi-download"
                onClick={() => this.downloadCSV(this.state.surveyCode)} />
              <Button label="Close" className="p-button-secondary" icon="pi pi-times"
                onClick={() => this.setState({modalDownloadCSV: false})} />
            </div>

        </Dialog>

        <DialogEditResult
          visible={this.state.displayModalEditResult}
          result={this.state.editResult}
          survey={this.state.survey}
          pages={this.state.pages}
          fields={this.state.fields}
          onHide={(success) => {
            this.setState({displayModalEditResult: false})
            if (success) {
              this.toast.show({
                severity: 'success',
                summary: 'Saved',
                detail: 'The result was saved',
              });
              this.listResults(this.state.surveyCode);
            }
          }}
        />

        <div className='table-title'>
          <label>Analyze{this.state.survey ?
            `:  ` + this.state.survey.label + ` (` +  this.state.survey.code + ')'
            : ''}
          </label>
        </div>

        <div>
          <InputText
            value={this.state.surveyCode}
            className="space-right"
            autoFocus={true}
            onChange={(event) => this.setState({surveyCode: event.target.value})}
            onKeyDown={(event) => {
                // enter key submits
                if (event.key==='Enter' && this.state.surveyCode.length === 6) {
                  this.listResults(this.state.surveyCode)
                }
              }
            }
          />

            <Button
              label="Open"
              className="space-right p-button-secondary"
              disabled={!this.state.surveyCode || this.state.surveyCode.length !== 6}
              icon="pi pi-cloud-download"
              onClick={() => this.listResults(this.state.surveyCode)}
            />

            <Button
              label="Download CSV"
              className="space-right p-button-secondary"
              disabled={!this.state.results}
              icon="pi pi-file-excel"
              onClick={() => this.setState({modalDownloadCSV: true})}
            />

            <Button
              label="Download KMZ"
              className="space-right p-button-secondary"
              disabled={!this.state.results || !this.state.survey || !this.state.survey.has_gps}
              icon="pi pi-globe"
              onClick={() => this.downloadKMZ(this.state.surveyCode)}
            />


        </div>

        <div style={{marginTop: '20px'}}>
          <TabView>

            <TabPanel header="Table" disabled={!this.state.results || !this.state.survey}>
              <DataTable
                value={this.state.results}

                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" rows={100}
                rowsPerPageOptions={[100,500,5000]}
                paginator

                scrollable
                scrollHeight="80vh"
                sortField="date_created"
                sortOrder={-1}
                loading={this.state.loading}
                >
                {dynamicColumns}
              </DataTable>
            </TabPanel>

            <TabPanel header="Map" disabled={!this.state.results || !this.state.survey || !this.state.survey.has_gps}>
              <AnalyzeMap
                survey={this.state.survey}
                pages={this.state.pages}
                fields={this.state.fields}
                results={this.state.results}
                resultsTimeStamp={this.state.resultsTimeStamp}
              />
            </TabPanel>

          </TabView>

        </div>

      </div>
    )
  }
}

export default Analyze;
