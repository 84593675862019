import React from 'react';

import {ToggleButton} from 'primereact/togglebutton';

export default class FieldDate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      field: this.props.field
    }
  }

  render() {
    return (
      <div>
        <div>
          <ToggleButton style={{minWidth:'110px', minHeight: '2em', marginRight: '5px'}}
            onIcon="pi pi-exclamation-triangle" offIcon="pi pi-question-circle"
            onLabel="Required" offLabel="Optional"
            checked={this.props.field.required}
            onChange={(e) => this.props.updateField({required: e.value})}
            />
        </div>
      </div>
    )
  }

}
