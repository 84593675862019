/**
 * general utilities
 */

 export const getToken = function() {
   if (localStorage.getItem('user')) {
     return JSON.parse(localStorage.getItem('user')).token;
   }
   return '';
 }

 export const isAdmin = function() {
   if (localStorage.getItem('user')) {
     return JSON.parse(localStorage.getItem('user')).is_admin;
   }
   return false;
 }

 export const getUser = function() {
   if (localStorage.getItem('user')) {
     return JSON.parse(localStorage.getItem('user'));
   }
 }

 // round a float to x decimal places
 export const roundNumber = function(number, places) {
   return Math.round((number + Number.EPSILON) * 10**places) / 10**places
 }

 // safe-get a nested dict / object, comes from here:
 // https://stackoverflow.com/questions/2631001/test-for-existence-of-nested-javascript-object-key
 export const getNested = function(obj, ...args) {
  return args.reduce((obj, level) => obj && obj[level], obj)
 }
