import React from 'react';

import { InputText } from 'primereact/inputtext';

export default class EditFieldText extends React.PureComponent {

  constructor(props) {
    super(props);

    // state is not need for text edit coz there is no pre/post processing required
    this.state = {}
  }

  render() {
    return (
      <div>
        <InputText
          value={this.props.value}
          onChange={(e) => this.props.onChange(this.props.field.uuid, e.target.value)}
        />
      </div>
    )
  }

}
