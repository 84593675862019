import React from 'react';

import _ from 'lodash';

import {Draggable} from 'react-beautiful-dnd';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Panel} from 'primereact/panel';

import icon_add_text from '../static/add_text.png';
import icon_add_number from '../static/add_number.png';
import icon_add_choice from '../static/add_choice.png';
import icon_add_mchoice from '../static/add_mchoice.png';
import icon_add_date from '../static/add_date.png';
import icon_add_gps from '../static/add_gps.png';
import icon_add_grid from '../static/add_grid.png';
import icon_add_photo from '../static/add_photo.png';
import drag_handle from '../static/drag_handle.png';

import InputTransparent from './InputTransparent';
import FieldChoice from './FieldChoice';
import FieldDate from './FieldDate';
import FieldGPS from './FieldGPS';
import FieldGrid from './FieldGrid';
import FieldText from './FieldText';
import FieldPhoto from './FieldPhoto';

// PureComponent has performance benefits over Component
export default class Field extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      field: this.props.field,
      displayModalDelete: false,
      deleting: false,
      collapsed: true,
      // used for the newly-added field transition
      new: this.props.field.new
    }
    this.scrollRef = React.createRef();
  }

  componentDidMount(){
    if (this.scrollRef.current) {
      // scroll to new field
      this.scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      // remove new-field css
      this.updateField({new: false});
    }
  }

  /** update field label */
  updateFieldLabel = value => {
    this.updateField({label: value})
  }

  updateFieldDescription = value => {
    this.updateField({description: value})
  }

  /** update field with values from incoming dict */
  updateField = dict => {
    const field = _.clone(this.state.field)
    _.extend(field, dict)
    this.setState({field: field})
    //propagate to survey state
    this.props.onFieldChange(field)
  }

  getIcon = () => {
    switch (this.state.field.type) {
      case 'text':
        return icon_add_text;
      case 'numeric':
        return icon_add_number;
      case 'choice':
        return icon_add_choice;
      case 'mchoice':
        return icon_add_mchoice;
      case 'date':
        return icon_add_date;
      case 'gps':
        return icon_add_gps;
      case 'grid':
        return icon_add_grid;
      case 'photo':
        return icon_add_photo;
      default:
        return null;
    }
  }

  onDeleteField = () => {
    this.setState({displayModalDelete: false})

    // mark the field as deleted, this will start the collapse animation
    this.setState({deleting: true})

    // set a timeout for when the animation has finished (300ms), to remove the
    // (now invisible) field from the state
    setTimeout(() => {
      this.props.onFieldDelete(this.state.field)
    }, 300);  // 300ms must match the css animation duration
  }


  /** return the field-type specific component, if one exists  */
  getFieldBody = () => {
    switch (this.state.field.type) {
      case 'text':
        return <FieldText field={this.state.field} updateField={this.updateField} />;
      case 'numeric':
        return <FieldText field={this.state.field} updateField={this.updateField} />;
      case 'choice':
        return <FieldChoice field={this.state.field} updateField={this.updateField} />;
      case 'mchoice':
        return <FieldChoice field={this.state.field} updateField={this.updateField} />;
      case 'date':
        return <FieldDate field={this.state.field} updateField={this.updateField} />;
      case 'gps':
        return <FieldGPS field={this.state.field} updateField={this.updateField} />;
      case 'grid':
        return <FieldGrid field={this.state.field} updateField={this.updateField} />;
      case 'photo':
        return <FieldPhoto field={this.state.field} updateField={this.updateField} />;
      default:
        return null;
    }
  }

  getDeleteDialog = () => {
    return <Dialog
      header="Delete field"
      visible={this.state.displayModalDelete}
      style={{minWidth: '50vw'}}
      closeOnEscape={true}
      onHide={() => this.setState({displayModalDelete: false})}
      >
      Are you sure ?
      <div style={{textAlign: 'right'}}>
        <Button label="Delete" icon="" onClick={() => this.onDeleteField()} />
        <Button label="Cancel" icon=""
          style={{marginLeft: "5px"}}
          className="p-button-secondary"
          onClick={() => this.setState({displayModalDelete: false})}
          />
      </div>
    </Dialog>

  }

  render() {

    return (
        <Draggable
          draggableId={this.props.field.uuid}
          index={this.props.index}
        >
          {provided => (

            <div className={
                  'field ' +
                  (this.state.deleting===true ? "delete-field"  : "") +
                  (this.props.field.new===true ? "added-field"  : "")
                  }
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {/* scroll to target --> */}
              <div ref={this.scrollRef} style={{position: 'relative', top: '-20px'}} />

                {/*  <!-- visible field title --> */}
                <div className="p-d-flex  p-ai-center p-flex-wrap">

                  <Button
                    icon={this.state.collapsed ? 'pi pi-angle-right': 'pi pi-angle-down'}
                    className="p-button-rounded p-button-secondary"
                    onClick={(e) => this.setState({collapsed: !this.state.collapsed})}
                    />

                  <img src={this.getIcon()} alt="text" className='fieldIcon' />

                  <InputTransparent
                    placeholder="Field title (*)"
                    value={this.state.field.label}
                    className="transparent-field"
                    onChange={(e) => this.updateFieldLabel(e.target.value)}
                  />
                </div>
                {/*  <!-- right-floating tools --> */}
                <div style={{position: 'absolute', right: '15px', top: '20px'}}>
                  <img src={drag_handle} alt="text" className='fieldIcon p-field' style={{width: '2rem'}} />
                  <Button
                    icon="pi pi-trash"
                    className="p-button-secondary"
                    onClick={(e) => this.setState({displayModalDelete: true})}
                    />
                </div>

                {/*  <!-- collapsable field part --> */}
                <Panel header="Header"
                  toggleable
                  collapsed={this.state.collapsed}
                  onToggle={(e) => this.setState({collapsed: e.value})}>

                  <InputTransparent
                    value={this.state.field.description || ''}
                    onChange={(e) => this.updateFieldDescription(e.target.value)}
                    placeholder="Field description"
                  />

                  {this.getFieldBody() }

                </Panel>

              { this.getDeleteDialog() }

            </div>

          )}
        </Draggable>


    )
  }

}
