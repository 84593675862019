import React from 'react';

class Privacy extends React.Component {

  render() {


    return (
      <div>

        <div className='table-title'>
          <label>Privacy policy</label>
        </div>


        <div style={{margin: '10px 0px 20px 0px'}}>

          We're super serious about privacy, and respecting the rights of our users. Rest assured that our business model
          is to help collect your data, for use by you, and only you. <br/><br/>

          <p>We built the TapSee app as a commercial application, available for free for trial users. This SERVICE is provided by TapSee and is intended for use as is.</p>

          <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>

          <h3>Information Collection and Use</h3>
          <p>For a better experience while using our Service, we may require you to provide us with a very limited amount of
          personally identifiable information, including your name and email address.
          The information that we request is retained on our server and used as described in this privacy policy.</p>

          <p>TapSee uses Google Maps Services which may collect information used to identify you. Their terms of service
          <a href="https://cloud.google.com/maps-platform/terms/" rel="noreferrer" target="_blank"> are available here.</a></p>

          <h3>Log Data</h3>
          <p>We want to inform you that whenever you use our Service, in case of an error in the app we collect
          data and information (through third party products) on your phone called Log Data. This Log Data
          may include information such as your devices’s Internet Protocol (“IP”) address, device name,
          operating system version, configuration of the app when utilising TapSee, installed apps
          (a.k.a your "package list"), the time and date of your use of the Service, and other statistics.</p>

          <h3>Security</h3>
          <p>We value your trust in providing us your Personal Information, thus we are striving to use
          commercially acceptable means of protecting it. Rest assured that all data transmitted over the internet
          is encrypted. Be assured also that TapSee stores absolutely no payment details, as these are handled
          exclusively by PayPal and are hence invisible to TapSee.</p>

          <h3>Children’s Privacy</h3>
          <p>TapSee is not addressed to users under the age of 13. We do not knowingly collect personal
          identifiable information from children under 13. In the case we discover that a child under 13
          has provided us with personal information, we immediately delete this from our servers. If you
          are a parent or guardian and you are aware that your child has provided us with personal
          information, please contact us so that we will be able to do necessary actions.</p>

          <h3>Changes to This Privacy Policy</h3>
          <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page
          periodically for any changes. We will notify you of any changes by posting the new Privacy Policy
          on this page. These changes are effective immediately, after they are posted on this page.</p>

          <h3>Contact Us</h3>
          <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to
          <a href='#/contact'> contact us.</a></p>

        </div>

      </div>
    )
  }
}

export default Privacy;
