import React from 'react';

import {Card} from 'primereact/card';

import devices from '../static/devices.png';
import tools from '../static/tools.png';
import offline from '../static/offline.png';
import logo from '../static/logo-hires.png';
import fileKML from '../static/file-kml.png';
import fileXLSX from '../static/file-xlsx.png';

class Home extends React.Component {

  render() {
    return (
      <div className="p-grid p-align-center" style={{marginTop: '30px'}}>

        <div className="p-col-12 home-text-1 center" style={{marginTop: '20px'}}>
          Collect information using your Android device.
          <div className="home-text-2">
            TapSee allows you create build rich questionnaires for field surveys, marketing campaigns, and much more.
          </div>
        </div>

        <div className="p-col-12 center" >
          <img src={devices} alt="devices" className="" style={{'width': '80%', 'maxWidth': '900px'}}/>
        </div>


        {/*  QUESTIONS  */}
        <div className="p-col-12 p-md-6">
          <Card title="Multiple question types"
                className="home-card p-d-flex p-jc-center p-ai-center">
            Questions can include text, choices, dates, GPS, photos, and grids.
          </Card>
        </div>
        <div className="p-col-12 p-md-6 center">
          <Card className="p-shadow-24">
            <img src={tools} alt='tools' style={{'maxWidth': '80%'}}/>
          </Card>
        </div>


        {/*  OFFLINE  */}
        <div className="p-col-12 p-md-6 center">
          <Card className="p-shadow-24">
            <img src={offline} alt='offline' style={{'maxWidth': '80%'}}/>
          </Card>
        </div>
        <div className="p-col-12 p-md-6">
          <Card title="Offline usage"
                className="home-card p-d-flex p-jc-center p-ai-center">
            Surveys can be conducted offline, and uploaded later when the network is available.
          </Card>
        </div>


        {/*  ANALYZE  */}
        <div className="p-col-12 p-md-6">
          <Card title="Analyse your results" className="home-card  p-d-flex p-jc-center p-ai-center">
            See your results appear on the website in realtime.
            <br/><br/>
            Visualise your geocoded results on the map.
            <br/><br/>
            Download your results as CSV or KMZ.
          </Card>
        </div>
        <div className="p-col-12 p-md-6 center">
          <Card className="p-shadow-24">
            <img src={fileKML} alt='kml' style={{'maxWidth': '120px', 'width': '20%'}}/>
            <img src={fileXLSX} alt='xlsx' style={{'maxWidth': '120px', 'width': '20%'}}/>
          </Card>
        </div>


        {/*  GET STARTED  */}
        <div className="p-col-12 p-md-6 center">
          <Card className="p-shadow-24">
            <img src={logo} alt='logo' style={{'maxWidth': '80%'}}/>
          </Card>
        </div>
        <div className="p-col-12 p-md-6">
          <Card title="Get started !" className="home-card  p-d-flex p-jc-center p-ai-center">
            <a href='/#/create/'>Create a survey</a> for free, no signup required
            <br/><br/>
            For more info about pricing and advanced features, checkout the <a href='/#/about/'>About page</a>
            <br/><br/>
            Questions ? <a href='/#/contact/'>Talk to us !</a>
          </Card>
        </div>

      </div>
    )
  }
}

export default Home;
