import React from 'react';

import {Fieldset} from 'primereact/fieldset';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';

import Api from '../utils/Api';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.Api = new Api();
    this.state = {username: 'admin', password: ''};
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin(event) {

    this.messages.clear();

    this.Api.auth.login({
      username: this.state.username,
      password: this.state.password
    }).then((res) => {
      this.messages.clear();
      this.props.onLogin(res);
    },(error) => {
      console.error(error);
      this.messages.clear();
      this.messages.show({
        closable: false,
        sticky: true,
        severity: 'error',
        summary: '',
        detail: 'Username / password incorrect'
      });
    });
  }

  render() {
    return (

      <div className="p-grid p-justify-center" style={{marginTop:50}}>
          <div className="p-col-6">
            <Fieldset legend="Login">

              <div className="p-grid">
                <div className="p-col-12">
                    <label>Username</label>
                </div>
                <div className="p-col-12">
                    <InputText
                      placeholder="user@foobar.com"
                      value={this.state.username}
                      onChange={(e) => this.setState({username: e.target.value})}
                      onKeyDown={(event) => {
                        // enter key submits
                        if (event.key==='Enter') {
                          this.handleLogin()
                        }
                      }}
                    />
                </div>

                <div className="p-col-12">
                    <label>Password</label>
                </div>
                <div className="p-col-12">
                    <Password
                      feedback={false}
                      name='password'
                      value={this.state.password}
                      onChange={(e) => this.setState({password: e.target.value})}
                      onKeyDown={(event) => {
                        // enter key submits
                        if (event.key==='Enter') {
                          this.handleLogin()
                        }
                      }}
                    />
                </div>

                <div className="p-col-12">
                    <Button label="Login" onClick={this.handleLogin}/>
                </div>

                <Messages ref={(el) => this.messages = el}></Messages>

              </div>
            </Fieldset>
          </div>
      </div>


    );
  }
}
export default Login;
