import React from "react";
import ReactDOM from "react-dom";

import { InfoWindow } from 'google-maps-react';

import _ from 'lodash';

import Api from '../utils/Api';
import {roundNumber, getNested} from '../utils/utils';

import ModalImage from './ModalImage';

export class AnalyzeMapinfoWindow extends React.PureComponent {

  constructor(props) {
    super(props);

    this.Api = new Api();
  }

  renderField(field) {

    switch (field.type) {
      case 'text':
        return this.props.result[field.uuid];
      case 'numeric':
        return this.props.result[field.uuid];
      case 'choice':
        return this.props.result[field.uuid];
      case 'mchoice':
        return this.props.result[field.uuid].join(', ');
      case 'date':
        return this.props.result[field.uuid];
      case 'gps':
        if (this.props.result[field.uuid].length === 0) return '';
        var lon = this.props.result[field.uuid][0];
        var lat = this.props.result[field.uuid][1];
        return roundNumber(lon, 6) + ', ' + roundNumber(lat, 6);
      case 'photo':
        return this.props.result[field.uuid].map((photoFilename, index) =>  {
          return <ModalImage
            className="analyze-photo"
            alt={photoFilename}
            uuids={this.props.result[field.uuid]}
            myIndex={index}
            key={index}>
          </ModalImage>
        });
      case 'grid':
        return this.buildGridField(field);

      default:
        return <b>Unsupported: type {field.type}</b>
    }
  }

  /*
  Return the table containing the headers and data for a grid field
  */
  buildGridField(field) {

    let colHeaders = field.config?.colHeaders;
    let rowHeaders = field.config?.rowHeaders;
    let uuids = field.config?.uuids;

    // problem with data structure, giving up
    if (!colHeaders || !rowHeaders || !uuids) {
      console.warn('invalid gridfile structure');
      return <div/>
    }

    return (
      <table className="map-grid-field">
        <thead>
          <tr>
            <th>{/* empty header column */}</th>
            {
              // for the first table row, loop over column headers
              colHeaders.map((item, index) => (
                <th key={'col' + index}>{item}</th>
              ))
            }
            </tr>
        </thead>
        <tbody>
            {
              // now loop over rowHeaders as a means of looping over (down) rows
              rowHeaders.map((item, index) => (
                // call renderRow which will output row header and loop over columns
                this.renderRow(field, item, index)
              ))
            }
        </tbody>
      </table>
    )
  }

  /*
  render a grid field row by printing the row header then looping over the row result values
  */
  renderRow(field, rowHeader, rowIndex)  {
    // get the list of (rows) containing the list of cols of uuids
    const gridFieldUuids = field.config.uuids;

    return (
      <tr key={rowIndex}>
        <td className="map-grid-field-header">{rowHeader}</td>
        {
          this.renderDataCells(
            field.uuid, gridFieldUuids[rowIndex]
          )
        }
      </tr>
    );
  }

  /**
  output normal data cell containing cell value
  */
  renderDataCells(fieldUuid, rowUuids)  {
    return (
      rowUuids.map((gridFieldUuid, colIndex) => {
        // safely obtain value for grid cell uuid from result dict
        const value = getNested(this.props.result, fieldUuid, gridFieldUuid);

        return (
          <td className="map-grid-data-cell" key={gridFieldUuid}>
            {value}
          </td>
        )
      })
    );
  }

  /*
  load the InfoWindow content in a way that allows onclick listening
  */
  onInfoWindowOpen(props, e) {
    var infoWindowFields = []

    if (this.props.result) {
      _.forEach(this.props.pages, (page, index) => {
        var pageFields = _.filter(this.props.fields, { pageUuid: page.uuid })
        _.forEach(pageFields, (field) => {
          infoWindowFields.push(field);
        })
      });
    }

    const container = (
      <table>
        <tbody>
          {
            infoWindowFields.map((field, index) => {
              return <tr key={index}>
                {/* render field label */}
                <td><b>{field.label} :</b></td>
                {/* render field value */}
                <td>{this.renderField(field)}</td>
               </tr>
            })
          }
          </tbody>
        </table>
    );
    ReactDOM.render(
      React.Children.only(container),
      // load the result into the div we gave in the InfoWindow, which will allow us to attach
      // onclick events which aren't normally possible otherwise with the google-maps-react project
      document.getElementById("iwc")
    );
  }

  render() {

    return (

      <InfoWindow
        google={this.props.google}
        map={this.props.map}
        visible={this.props.visible}
        marker={this.props.marker}
        onOpen={e => {
            this.onInfoWindowOpen(this.props, e);
          }}
        >

          <div id="iwc" />

        </InfoWindow>
    )
  }
}

export default AnalyzeMapinfoWindow;
