import React from 'react';

import { Dropdown } from 'primereact/dropdown';


export default class EditFieldChoice extends React.PureComponent {

  constructor(props) {
    super(props);

    // convert incoming field options into a list of dicts suitable for the Dropdown widget
    let options = [];
    if (this.props.field.options) {
      this.props.field.options.forEach((item, index) => {
        options.push({value: item, label: item});
      });
    }

    this.state = {
      options: options
    }
  }


  render() {

    if (!this.props.field) return <div />

    return (
      <div>
        <Dropdown
          value={this.props.value}
          options={this.state.options}
          onChange={(e) => this.props.onChange(this.props.field.uuid, e.value)}
        />
      </div>
    )
  }

}
