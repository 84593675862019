import React from 'react';
import Moment from 'react-moment';

import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';

import _ from 'lodash';
import byteSize from 'byte-size'

import Api from '../utils/Api';

import DialogPassword from './DialogPassword';
import {getUser, isAdmin} from '../utils/utils.js'


class Clients extends React.Component {


  constructor(props) {
    super(props);

    this.Api = new Api();

    // this page requires login
    var user = getUser();
    if (!user) {
      // somehow we ended up at this route with no user, redirect to login
      window.location.href = "/#/login";
    }

    this.state = {
      user: user,
      surveys: [],
      count: 0,
      displayModalDelete: false,
      displayModalPassword: false,
      password1: '',
      password2: '',
      loading: false
    }

    this.listUserSurveys = this.listUserSurveys.bind(this);
  }

  componentDidMount() {
      // load the data once-off
      this.listUserSurveys()

      // This is a hacky refresh of the user retrive serializer (save as returned by the login endpoint),
      // in order to refresh the num_bytes / max_bytes fields for the user.
      // This should probably by moved to run for every new window/tab instance.
      this.Api.users.fetch(this.state.user.username).then((userDetail) => {
        if (userDetail) {
          console.log(this.state.user)
          let currentUser = _.cloneDeep(this.state.user) || {};
          currentUser = _.merge(currentUser, userDetail)
          this.setState({user: currentUser});
          console.log(currentUser)
          localStorage.setItem('user', JSON.stringify(currentUser));
        }
      })
  }

  /*
  load intial dataset which will be sliced / sorted / filtered later on
  */
  listUserSurveys = () => {
    this.setState({loading: true});

    this.Api.surveys.list().then((res) => {

      this.setState({
        surveys: res.results,
        count: res.count
      });

    }).catch((err) => {
      // default message
      let message = 'Unknown error';

      // if error object is a dict, try to extract info from it
      if (err.constructor === Object) {
        if (err.message) {
          message = err.message;
        }
      } else if (err.statusText) {
        message = err.statusText;
      }

      if (this.toast) {
        this.toast.show({
          severity: 'error',
          summary: 'Error',
          detail: message,
          style: {'white-space': 'pre-wrap'}
        });
      }
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  deleteSurvey = (surveyCode) => {
    this.setState({displayModalDelete: false});

    this.Api.surveys.delete(surveyCode).then((res) => {
      this.toast.show({
        severity: 'success',
        summary: 'Success',
        detail: <div>Survey {surveyCode} deleted</div>
      });
      this.listUserSurveys();

    }).catch((err) => {
      var message = err.message
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: err + message,
        style: {'white-space': 'pre-wrap'}
      });
    })
  }

  cloneSurvey = (surveyCode) => {
    this.Api.surveys.clone(surveyCode).then((res) => {

      this.toast.show({
        severity: 'success',
        summary: 'Success',
        detail: <div>New survey created: <a href={'#/create/' + res.survey_code + '/'}> {res.survey_code} </a></div>
      });
      this.listUserSurveys();

    }).catch((err) => {

      console.error(err);
      var message = err;
      if (err.message) {
        message = err.message;
      }
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        style: {'white-space': 'pre-wrap'}
      });

    })
  }

  changePassword = (password) => {

    this.Api.users.changePassword(password).then((res) => {

      this.toast.show({
        severity: 'success',
        summary: 'Success',
        detail: <div>Password sucessfully changed.</div>
      });
      this.setState({displayModalPassword: false});

    }).catch((err) => {

      console.error(err);
      var message = err;
      if (err.message) {
        message = err.message;
      }
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        style: {'white-space': 'pre-wrap'}
      });

    });

  }

  templateSurveyCode = (rowData, column) => {
    let surveyCode = rowData[column.field];
    return <a href={'#/analyze/' + surveyCode + '/'}>{surveyCode}</a>;
  }

  /*
  Format incoming ISO/UTC date to human-friendly, local timezone
  */
  templateDate(rowData, column) {
    if (rowData[column.field] == null) return ''
    return <Moment format="YYYY-MM-DD HH:mm">{rowData[column.field]}</Moment>
  }

  /*
  Survey bytes used
  */
  templateBytes(rowData, column) {
    if (rowData[column.field] == null) return '0 B'
    var data = byteSize(rowData[column.field], { units: 'metric' });
    return data['value'] + ' ' + data['unit'];
  }

  /*
  Action buttons
  */
  templateActions = (rowData, column) => {
    let surveyCode = rowData['code'];
    return <div>
      <Button label="" className="space-right" icon="pi pi-copy"
        tooltip="Clone survey" tooltipOptions={{position: 'top'}}
        onClick={() => this.cloneSurvey(surveyCode)} />
      <Button label="" className="space-right" icon="pi pi-trash"
        tooltip="Delete survey" tooltipOptions={{position: 'top'}}
        onClick={() => this.setState({displayModalDelete: true, deleteSurveyCode: surveyCode})} />
    </div>;

  }

  /*
  cell content for cells waiting for the virtual scroll load
  */
  templateLoading() {
    return <span className="datatable-loading-cell"></span>;
  }

  formatBytes(num_bytes) {
    var data = byteSize(num_bytes, { units: 'metric' });
    return data['value'] + ' ' + data['unit'];
  }

  render() {

    // we arrive here before the redirect to login page is processed, so in the meantime do nothing
    if (!this.state?.user) {
      return <div />
    }

    return (
      <div>

        <Toast ref={(el) => this.toast = el} />

        <Dialog
          header="Delete survey"
          visible={this.state.displayModalDelete}
          style={{minWidth: '50vw'}}
          onHide={() => this.setState({displayModalDelete: false})}>
            <p>Are you sure you want to delete survey: <b>{this.state.deleteSurveyCode}</b></p>
            <div style={{textAlign: 'right'}}>
              <Button label="Cancel"className="space-right" icon="pi pi-times"
                onClick={() => this.setState({displayModalDelete: false})} />
              <Button label="Delete" icon="pi pi-trash"
                onClick={() => this.deleteSurvey(this.state.deleteSurveyCode)} />
            </div>
        </Dialog>

        <DialogPassword
          callback={this.changePassword}
          visible={this.state.displayModalPassword}
          onHide={() => {this.setState({displayModalPassword: false})}} />

        <div className='table-title'>
          <label>My account</label>
        </div>

        <div>
          <Button
            label="Change password"
            className="space-right p-button-secondary"
            icon="pi pi-lock"
            onClick={() => {this.setState({displayModalPassword: true})}}
          />
        </div>

        <div style={{marginTop: '20px'}}>
          Disk usage: {this.formatBytes(this.state.user.num_bytes)} (limit: {this.formatBytes(this.state.user.max_bytes)})<br/>
          Expiry date: <Moment format="YYYY-MM-DD HH:mm">{this.state.user.expiry_date}</Moment><br/>
        </div>

        <div style={{marginTop: '20px'}}>
          <DataTable
            value={this.state.surveys}

            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={100}
            rowsPerPageOptions={[100,200,500]}
            paginator

            sortField="date_created"
            sortOrder={-1}
            loading={this.state.loading}
          >

            <Column field="code" header="Code" body={this.templateSurveyCode}
              filter={true} filterMatchMode="contains" loadingBody={this.templateLoading}
              bodyClassName="clientTableColumnBody">
            </Column>
            <Column field="label" header="Label"
              filter={true} sortable={true} filterMatchMode="contains" loadingBody={this.templateLoading}
              bodyClassName="clientTableColumnBody">
            </Column>
            {
                isAdmin &&
                <Column field="username" header="Username" filter={true}
                  sortable={true} filterMatchMode="contains" loadingBody={this.templateLoading}
                  bodyClassName="clientTableColumnBody">
                </Column>
            }
            <Column field="date_created" header="Date created" body={this.templateDate}
              sortable={true} filter={true} filterMatchMode="contains"
              loadingBody={this.templateLoading} bodyClassName="clientTableColumnBody">
            </Column>
            <Column field="date_expires" header="Date expires" body={this.templateDate}
              sortable={true} filter={true} filterMatchMode="contains"
              loadingBody={this.templateLoading} bodyClassName="clientTableColumnBody">
            </Column>
            <Column field="num_results" header="Num. results"
              sortable={true} loadingBody={this.templateLoading}
               bodyClassName="clientTableColumnBody">
            </Column>
            <Column field="num_bytes" header="Storage used" body={this.templateBytes}
              sortable={true} loadingBody={this.templateLoading}
               bodyClassName="clientTableColumnBody"
            ></Column>
            <Column header="Actions" body={this.templateActions} loadingBody={this.templateLoading}
               bodyClassName="clientTableColumnBody">
            </Column>

          </DataTable>
        </div>

      </div>
    )
  }
}

export default Clients;
