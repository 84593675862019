import React from 'react';

import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import _ from 'lodash';

import InputTransparent from './InputTransparent';
import Page from './Page';

export default class Survey extends React.Component {

  /**
  serialize survey state model to JSON payload for server
  */
  static serialize = (surveyProps) => {

    var data = _.clone(surveyProps.survey);

    data.pages = []
    var surveyPages = _.cloneDeep(surveyProps.pages);
    _.forEach(surveyPages, function(page, index) {
      page.fields = []

      _.forEach(surveyProps.fields, function(field, index) {
        if (field.pageUuid === page.uuid) {
          var fieldPayload = _.extend({}, field)
          // remove some fields not interesting for the backend
          fieldPayload = _.omit(fieldPayload, ['pageUuid'])
          page.fields.push(fieldPayload)
        }

      });
      data.pages.push(page);

    })

    return data;
  }

  /**
  deserialize JSON payload to survey state model
  */
  static deserialize = (payload) => {

    var survey = {
      label: payload.label,
      code: payload.code,
      uuid: payload.uuid
    }
    var pages = []
    var fields = []
    var has_gps = false;

    _.forEach(payload.pages, function(page) {
      pages.push({
        label: page.label,
        uuid: page.uuid,
        collapsed: false
      })

      _.forEach(page.fields, function(field) {

        // var fieldType = field.type;
        // if (field.type == 'mchoice') {

        // }

        fields.push({
          label: field.label,
          description: field.description,
          uuid: field.uuid,
          pageUuid: page.uuid,
          type: field.type,
          required: field.required,
          config: field.config,
          options: field.options
        })

        if (field.type === 'gps') has_gps = true;
      });

    survey.has_gps = has_gps;

    });

    return {survey: survey, pages: pages, fields: fields};
  }


  /** Return the index of a field or page based on its id */
  getIndex = (fields, fieldUuid) => {
      var foundIndex = -1
      _.forEach(fields, function(field, index) {
        if (field.uuid === fieldUuid) {
          foundIndex = index;
          return false;
        }
      })
      return foundIndex;
  }

  /** Update survey state in Create.js parent component at the end of a drag event*/
  onDragEnd = result => {

    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // this is a real dnd, treat it according to the type of object being dnded
    if (type === 'field') {
      this.onDragEndField(draggableId, source, destination)
    } else if (type === 'page') {
      this.onDragEndPage(source, destination)
    }

  }

  expandPage = (pageUuid, callback) => {

    // get a deep copy of the pages to avoid modifying the local props
    const pages = _.cloneDeep(this.props.pages);

    // find our page
    var pageIndex = this.getIndex(pages, pageUuid);
    if (pages[pageIndex].collapsed) {
      // page is closed, lets open it
      pages[pageIndex].collapsed = false;
      // update survey state in Create.js parent component
      this.props.onPagesChange(pages, callback);
    }
  }

  onDragEndPage = (source, destination) => {
    // copy the page being dragged to be spliced back in later
    const sourcePage = _.cloneDeep(this.props.pages[source.index]);

    // get a deep copy of the survey to avoid modifying the local props
    const pages = _.cloneDeep(this.props.pages);

    // manipulate the local copy
    pages.splice(source.index, 1)
    pages.splice(destination.index, 0, sourcePage)

    // update survey state in Create.js parent component
    this.props.onPagesChange(pages)
  }

  /** Return the field index for the given field, now that it has been moved to the given index inside
  its new page, in the context of all the existing fields for above pages up until this page / index
  **/
  getAbsIndex = (field, inPageIndex) => {

    var absIndex = -1;
    var counter = 0;

    var fields = this.props.fields;

    // loop over pages
    _.forEach(this.props.pages, function(page) {
      // if this is the target page, the abs index is our accumulated counter + the new/destination index
      if (page.uuid === field.pageUuid) {
        absIndex = counter + inPageIndex;
        return false;
      } else {
        // otherwise, we need to count the number of fields in this page, excluding this field in case
        // it is moving out of this page
        var fieldsInPage = _.filter(fields, { pageUuid: page.uuid });
        // increment our counter with the number of fields in this page, excluding our given field
        // if it occurs
        counter = counter + _.reject(fieldsInPage, {uuid: field.uuid}).length
      }
    })

    return absIndex;
  }

  onDragEndField = (draggableId, source, destination) => {

    // determine the (absolute) fieldIndex from its ids
    var fieldIndex = this.getIndex(this.props.fields, draggableId);

    // copy the field being dragged to be spliced back in later
    const sourceField = _.clone(this.props.fields[fieldIndex]);
    // set the new page uuid for the field being dragged
    sourceField.pageUuid = destination.droppableId;

    // get the new absolute index for this field, accounting for the fields in 'above' pages
    var newAbsFieldIndex = this.getAbsIndex(sourceField, destination.index);

    // get a deep copy of the survey to avoid modifying the local props
    const fields = _.clone(this.props.fields);

    // manipulate the local copy
    fields.splice(fieldIndex, 1);
    fields.splice(newAbsFieldIndex, 0, sourceField);

    // expand the page after the drag and drop
    this.expandPage(destination.droppableId);

    // update state in Create.js parent component
    this.props.onFieldsChange(fields);
  }

  /** update survey label */
  updateSurveyLabel = (event) => {
    // we can do a shallow clone coz label is at the first level
    const survey = _.clone(this.props.survey);
    survey.label = event.target.value;
    this.props.onSurveyChange(survey)
  }

  render() {
    return (

      <div  className="survey">

        <DragDropContext onDragEnd={this.onDragEnd}>


          <div className="p-d-flex  p-ai-center p-flex-wrap">
          <InputTransparent
            value={this.props.survey.label}
            onChange={(e) => this.updateSurveyLabel(e)}
            placeholder="Survey title (*)"
            style={{marginTop: '20px'}}
            autoFocus={true}
          />
          </div>

          <Droppable droppableId="pages" type="page">
            {provided => (

              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {this.props.pages.map((page, index) =>
                  <Page
                    key={page.uuid}
                    page={page}
                    fields={_.filter(this.props.fields, { pageUuid: page.uuid })}
                    index={index}
                    survey={this.props.survey}
                    onSurveyChange={this.props.onSurveyChange}
                    onPagesChange={this.props.onPagesChange}
                    onPageChange={this.props.onPageChange}
                    onPageDelete={this.props.onPageDelete}
                    onFieldChange={this.props.onFieldChange}
                    onFieldDelete={this.props.onFieldDelete}
                  />
                )}
                {provided.placeholder}
              </div>

            )}
          </Droppable>

        </DragDropContext>
      </div>
    )
  }
}
