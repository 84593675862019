import React from 'react';

import {InputText} from 'primereact/inputtext';

export default class InputTransparent extends React.Component {

  onChange = (event) => {
    return this.props.onChange(event);
  }

  render() {
    return (
      <InputText
        value={this.props.value}
        onChange={(e) => this.onChange(e)}
        className={this.props.className || "transparent-field"}
        style={this.props.style}
        placeholder={this.props.placeholder}
        keyfilter={this.props.keyfilter}
      />
    )
  }

}
