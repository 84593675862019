/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */

import {getToken} from './utils';


function parseJSON(response) {
  if (response && response.headers) {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json();
    }
  }
  // we return an empty dict if not data could be extracted
  return Promise.resolve({});
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
 function checkStatus(response) {

  // success case
  if (response.status >= 200 && response.status < 300) {
    return parseJSON(response);;
  }

  // 400 code should be a json response, but we mark it as rejected so that it arrives
  // in the page catch() method
  if (response.status === 400) {
    return parseJSON(response).then(data => Promise.reject(data));
  }

  // bad auth, force login
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem('user');
    window.location.href = "/#/login";
    return
  }

  // if we're here then the server either didn't respond or gave a 500
  return Promise.reject(response)
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const request = function(url, options) {

  // update the headers with auth and content type info, if it exists
  const base_headers = {
    'Content-Type': 'application/json'
  }
  if (localStorage.getItem('user') && url.indexOf('login') === -1) {
    base_headers['Authorization'] = 'Token ' + getToken();
  }
  const headers = {
    ...base_headers,
    ...options.headers
  }
  options.headers = headers;

  // checkStatus will in turn call the parseJson or raise depending on results
  return fetch(url, options)
    .then(checkStatus);
}
