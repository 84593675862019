import React from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import Api from '../utils/Api';


export class ModalImage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.Api = new Api();

    this.state = {
      maximised: false,
      uuids: this.props.uuids,
      myIndex: this.props.myIndex
    }

    this.smallUrl = this.Api.photos.getUrl(this.state.uuids[this.state.myIndex], true);

    this.onClickMax = this.onClickMax.bind(this);
    this.getLargeUrl = this.getLargeUrl.bind(this);
    this.moveRight = this.moveRight.bind(this);
    this.moveLeft = this.moveLeft.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);

    this.buttonLeft = React.createRef();
    this.buttonRight = React.createRef();
  }

  /*
  respond to keypresses on the buttons to move the images left and right
  */
  onKeyPressed(event) {
    if(event.key === "ArrowLeft") {
      this.moveLeft();
    }
    if(event.key === "ArrowRight") {
      this.moveRight();
    }
  }


  getLargeUrl() {
    return this.Api.photos.getUrl(this.state.uuids[this.state.myIndex]);
  }

  moveLeft() {
    var newIndex = Math.max(0, this.state.myIndex - 1);
    this.setState({myIndex: newIndex})
  }

  moveRight() {
    var newIndex = Math.min(this.state.uuids.length - 1, this.state.myIndex + 1);
    this.setState({myIndex: newIndex})
  }

  /*
  user clicks on small image to maximise it
  */
  onClickMax() {
    this.setState({maximised: true});
  }


  render() {

    return (
      <div>

        <img src={this.smallUrl} alt='' className="modal-image-small" onClick={this.onClickMax}/>

        <Dialog
          header=""
          visible={this.state.maximised}
          style={{width: '90vw', border: 'none'}}
          onHide={() => this.setState({maximised: false})}
          showHeader={false}
          contentStyle={{textAlign: 'center'}}
          dismissableMask={true}
          appendTo={document.body}
          blockScroll={true}
          closeOnEscape={true}
          >

            <div className="p-grid p-align-center p-nogutter">
              <div className="p-col-fixed" style={{ width: '50px'}}>
                <Button
                  label=""
                  className="p-button-rounded p-button-text"
                  disabled={false}
                  icon="pi pi-angle-left"
                  onClick={this.moveLeft}
                  tabIndex={1}
                  onKeyDown={this.onKeyPressed}
                  ref={el => this.buttonLeft = el}
                />
              </div>

              <div className="p-col">
                <img src={this.getLargeUrl()} alt=''
                  className="modal-image-large"
                  onClick={() => this.setState({maximised: false})}
                  />
              </div>

              <div className="p-col-fixed" style={{ width: '50px'}}>
                <Button
                  label=""
                  className="p-button-rounded p-button-text"
                  disabled={false}
                  icon="pi pi-angle-right"
                  onClick={this.moveRight}
                  tabIndex={0}
                  onKeyDown={this.onKeyPressed}
                  autoFocus={true}
                  ref={el => {this.buttonRight = el}}
                />
              </div>
            </div>

        </Dialog>
      </div>

    )
  }
}

export default ModalImage;
