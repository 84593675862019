import React from 'react';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import _ from 'lodash';

import AnalyzeMapInfoWindow from './AnalyzeMapInfoWindow';

export class AnalyzeMap extends React.PureComponent {

  constructor(props) {
    super(props);

    this.gpsField = _.find(props.fields, { 'type': 'gps' });
    if (!this.gpsField) return;

    this.state  = {
      results: props.results,
      activeMarker: null,
      showingInfoWindow: false,
      infoWindowTuples: [],
      markers: this.getMarkers()
    };

    //{
      // fields: (18) [{label: "Café Name", uuid: "9e439e0f-e315-4399-88cd-beb26be001ae", pageUuid: "c4679801-7e7e-4368-a536-3e5d31545da4", type: "text", required: true, …}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
      // google: {maps: {…}}
      // loaded: true
      // pages: (3) [{…}, {…}, {…}]
      // results: [{3e76c4d0-92e9-4f95-9055-8cd7ba1ec27e: "", 3f07b838-1eff-46f9-9e41-c74a8c6a849d: Array(0), 453818b0-a182-4030-8a1f-f0ccea335958: Array(4), 694b0241-4920-49f0-a99a-6990ec8e19c2: Array(2), 69d84ef3-5c42-4d89-9025-43054c01cf49: "", …}]
      // survey: {label: "Test Survey", code: "aaaaaa"}
    //}

  }

  componentDidUpdate(prevProps) {
    if (prevProps.resultsTimeStamp !== this.props.resultsTimeStamp) {
      // we've detected a new dataset load, force the data (markers + bounds) to refresh
      this.refreshData()
    }

  }

  refreshData() {
    this.setState({markers: this.getMarkers()});
    if (this.map) {
      this.calculateMapBounds(this.mapProps, this.map);
    }
  }


  onMarkerClick = (props, marker, e) => {
    var result = props.result;

    this.setState({
      infoResult: result,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  onMapClick = (props) => {
    this.setState({
      infoResult: null,
      showingInfoWindow: false,
      activeMarker: null
    })
  };

  onMapReady(mapProps, map) {
    // set these handles in able to reset the bounds during the life of the map, if neccessary
    this.mapProps = mapProps;
    this.map = map;

    this.calculateMapBounds(mapProps, map);
  }

  calculateMapBounds(mapProps, map) {
    // zoom map to markers bounds on load
    var google = this.props.google;
    var bounds = new google.maps.LatLngBounds();
    this.props.results.map((result, index) => {
      var resultGps = result[this.gpsField.uuid];
      if (resultGps && resultGps.length === 4) {
        bounds.extend({lng: resultGps[0], lat: resultGps[1]});
      }
      return true;
    });

    // in case there is only 1 point enlarge the view a bit
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
      var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }
    map.fitBounds(bounds);
  }

  /**
   Return the list of <Marker> instances for the map. Called once on module load
   to avoid re-calculating for each render()
  */
  getMarkers() {

    return this.props.results.map((result, index) => {
      var resultGps = result[this.gpsField.uuid];
      if (!resultGps) return false;
      return <Marker
        key={index}
        position={{lng: resultGps[0], lat: resultGps[1]}}
        onClick={this.onMarkerClick}
        result={result}
      />
    })

  }

  render() {

    const containerStyle = {
      position: 'relative',
      width: '100%',
      height: '100%',
      minHeight: '100vh'
    }

    return (
      <Map
        google={this.props.google}
        zoom={15}
        containerStyle={containerStyle}
        onClick={this.onMapClick}
        onReady={(mapProps, map) => this.onMapReady(mapProps, map)}
      >

        {
          this.state.markers
        }

        <AnalyzeMapInfoWindow
          pages={this.props.pages}
          fields={this.props.fields}
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          result={this.state.infoResult}
          />

      </Map>
    )
  }

}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAYzB5BDFrHTPafwZGl2zzida9DW_JBpEE'
})(AnalyzeMap)
