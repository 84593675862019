import React from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Password} from 'primereact/password';

class DialogPassword extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.visible,
      password1: '',
      password2: '',
    }
    this.onHide = this.onHide.bind(this);

  }

  onHide() {
    this.setState({visible: false});
  }

  render() {
    // ensure required fields
    const isNotValid = this.state.password1.length < 6 || (this.state.password1 !== this.state.password2);

    const footer = (
      <div>
        <Button label="Cancel"className="space-right" icon="pi pi-times"
              onClick={this.props.onHide} />
        <Button label="OK"
          disabled={isNotValid}
          onClick={() => this.props.callback(this.state.password1)} />
      </div>
    );

    return (
      <div>

        <Dialog
          header="Change password"
          onHide={this.props.onHide}
          visible={this.props.visible}
          dismissableMask={true}
          className={"dialog"}
          footer={footer}>

          <div>

            <p>New password (6 chars minimum):</p>
            <Password
              className="p-d-block p-mb-2"
              placeholder="new password"
              value={this.state.password1}
              onChange={(e) => this.setState({password1: e.target.value})}
            />
            <p>Confirm:</p>
            <Password
              className="p-d-block p-mb-2"
              placeholder="verify"
              value={this.state.password2}
              onChange={(e) => this.setState({password2: e.target.value})}
            />

          </div>

        </Dialog>

      </div>
    );
  }
}

export default DialogPassword
