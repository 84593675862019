import React, { Component } from 'react';

import { HashRouter } from 'react-router-dom';

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.scss';

import './App.css';

import MainPage from './components/MainPage'
import Footer from './components/Footer'


class App extends Component {
  render() {
    return (
        <div className="root-child">
          <HashRouter>
            <MainPage />
            <Footer />
          </HashRouter>
        </div>
    );
  }
}

export default App;
